import { extendObservable, observable, action, makeAutoObservable } from 'mobx';

import { superFetch } from '../lib/fetch';

class MessageModel {
  constructor(id, message, severity) {
    makeAutoObservable(this);
    extendObservable(this, {
      id: id,
      message: message,
      severity: severity,
      get numericSeverity() {
        if (this.severity === 'error') {
          return 2;
        }
        if (this.severity === 'warning') {
          return 1;
        }
        return 0;
      },
      visible: true,
      dismiss: action(() => {
        console.log('dismissing', this.id);
        this.visible = false;
      }),
    });
  }
}

function isMasked(identifier, allMessages) {
  switch (identifier) {
    case 'imu:not_calibrated':
      return true; // External IMU is always used
    case 'satellite:no_coverage':
      return true; // Always using external PPS and not internal GPS
    case 'global:missing_acoustic':
      return (
        allMessages.indexOf('acoustic:missing_external_depth') >= 0 ||
        allMessages.indexOf('acoustic:missing_vehicle_imu') >= 0
      );
    default:
      return false;
  }
}

function isInternetExplorer() {
  // Detect MSIE up to version 10
  // https://stackoverflow.com/questions/24861073/detect-if-any-kind-of-ie-msie
  if (navigator.appVersion.indexOf('MSIE') !== -1) {
    return true;
  }
  // Detect MSIE 11
  // https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  if (isIE11) {
    return true;
  }
  return false;
}

const msie_id = 'msie';
const msie_msg =
  'You are using the Internet Explorer browser which is not supported. Feel free to look around, but beware that everything might not work as expected';
const fetcherror_id = 'fetcherror';
const fetcherror_msg = 'No connection with server';

function severityCompare(a, b) {
  if (a.numericSeverity > b.numericSeverity) {
    return -1;
  }
  if (a.numericSeverity < b.numericSeverity) {
    return 1;
  }
  return 0;
}

class MessageStore {
  constructor() {
    extendObservable(this, {
      hasLoaded: false,
      isLoading: false,
      isStoring: false,
      messageRegistry: observable.map(),
      get count() {
        return this.messageRegistry.size;
      },
      clear: action(() => {
        this.messageRegistry.clear();
      }),
      addMessage: action((id, message, severity) => {
        this.messageRegistry.set(id, new MessageModel(id, message, severity));
      }),
      addMessageIfNotExists: action((id, message, severity) => {
        if (!this.messageRegistry.has(id)) {
          this.addMessage(id, message, severity);
        }
      }),
      get: (id) => {
        return this.messageRegistry.get(id);
      },
      get all() {
        return Array.from(this.messageRegistry.values());
      },
      get allIds() {
        return Array.from(this.messageRegistry.keys());
      },
      removeIds: action((idList) => {
        idList.forEach((id) => {
          this.messageRegistry.delete(id);
        });
      }),
      get allVisible() {
        return this.all.filter((d) => d.visible);
      },
      getMasked: () => {
        const unsorted = this.allVisible.filter((d) => {
          return !isMasked(d.id, this.allIds);
        });
        // Sort messages by priority
        return unsorted.sort(severityCompare);
      },
      fetch: action(() => {
        this.isLoading = true;
        return superFetch('/api/v1/warnings')
          .then((data) => {
            this.hasLoaded = true;
            this.isLoading = false;
            this.loadingError = '';

            const currentIds = data.map((d) => d.id);
            currentIds.push(msie_id); // Don't remove MSIE warning

            // Find ids in registry to that are no longer active
            const remove = this.allIds.filter((id) => currentIds.indexOf(id) === -1);
            this.removeIds(remove);

            // Add new messages
            data.forEach((d) => {
              this.addMessageIfNotExists(d.id, d.message, d.severity);
            });
          })
          .catch((e) => {
            this.isLoading = false;
            this.loadingError = e.toString();
            console.log('load error', this.loadingError);
            this.addMessage(fetcherror_id, fetcherror_msg, 'error');
          });
      }),
    });
    if (isInternetExplorer()) {
      this.addMessage(msie_id, msie_msg, 'info');
    }
  }
}

export default MessageStore;
