import { extendObservable, action, observable, makeAutoObservable } from 'mobx';

import { superFetch, superFetchHeaders } from '../lib/fetch';

class PointOfInterestItemStore {
  constructor(id, x, y, z, cage_id, operation_id, hidden, name, ts) {
    makeAutoObservable(this);
    extendObservable(this, {
      id: id,
      x: x,
      y: y,
      z: z,
      cage_id: cage_id,
      operation_id: operation_id,
      name: name,
      ts: ts,
      hidden: hidden,
      highlighted: false, // Used to highlight a POI
      isEditing: false,
      isLoading: false,
      errorUpdate: '',
      errorDelete: '',
      setEditing: action((isEditing) => {
        this.isEditing = isEditing;
      }),
      setHighlighted: action((isHighlighted) => {
        this.highlighted = isHighlighted;
      }),
    });
  }
}

class PointsOfInterestStore {
  constructor() {
    makeAutoObservable(this);
    extendObservable(this, {
      points: observable.map(),
      add: action((id, x, y, z, cage_id, operation_id, hidden, name = null, ts = null) => {
        this.points.set(id, new PointOfInterestItemStore(id, x, y, z, cage_id, operation_id, hidden, name, ts));
      }),
      delete: action((id) => {
        this.points.delete(id);
      }),
      get: (id) => {
        return this.points.get(id);
      },
      get all() {
        return Array.from(this.points.values());
      },
      get allVisible() {
        return Array.from(this.points.values()).filter((d) => !d.hidden);
      },
      getHref: (id) => {
        return '/api/v1/poi/' + id;
      },
      addPersistent: action((x, y, z, cage_id, operation_id, hidden = false, name = null) => {
        const _name = name || 'Type';
        let data = {
          name: _name,
          x: x,
          y: y,
          z: z,
          cage_id: cage_id,
          operation_id: operation_id,
          hidden: hidden,
          ts: '2018-07-17T10:27:29.699536549+02:10', // Dummy timestamp needs to be here, but the real timestamp is added by the backend.
        };

        return superFetchHeaders(this.getHref(''), 'POST', data)
          .then((response) => {
            if (response.status === 201) {
              return superFetchHeaders(response.headers.get('Location'));
            } else {
              throw Error('Unable to add POI: ' + response.statusText);
            }
          })
          .then((response) => {
            if (response.status !== 200) {
              throw Error('Unable to fetch newly added POI: ' + response.statusText);
            }
            return response;
          })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.add(data.id, data.x, data.y, data.z, data.cage_id, data.operation_id, data.hidden, data.name, data.ts);
          })
          .catch((err) => {
            throw Error(err.toString());
          });
      }),

      updatePersistent: action((id) => {
        let point = this.get(id);
        point.isLoading = true;
        point.errorUpdate = '';
        let data = {
          name: point.name,
          x: point.x,
          y: point.y,
          z: point.z,
          cage_id: point.cage_id,
          operation_id: point.operation_id,
          hidden: point.hidden,
          ts: point.ts,
        };
        return superFetchHeaders(this.getHref(id), 'PATCH', data)
          .then((response) => {
            if (response.status !== 204) {
              throw Error('Unable to update POI: ' + response.statusText);
            }
            point.isEditing = false;
            point.isLoading = false;
            point.errorUpdate = '';
          })
          .catch((err) => {
            point.isEditing = true;
            point.isLoading = false;
            point.errorUpdate = err.toString();
            setTimeout(() => {
              point.errorUpdate = '';
            }, 3000);
          });
      }),
      deletePersistent: action((id) => {
        let point = this.get(id);
        point.isLoading = true;
        point.errorDelete = '';
        return superFetchHeaders(this.getHref(id), 'DELETE')
          .then((response) => {
            if (response.status !== 204) {
              throw Error('Unable to delete POI: ' + response.statusText);
            }
            this.delete(id);
            point.isLoading = false;
            point.errorDelete = '';
          })
          .catch((err) => {
            point.isLoading = false;
            point.errorDelete = err.toString();
            setTimeout(() => {
              point.errorDelete = '';
            }, 3000);
          });
      }),

      fetch: action(() => {
        return superFetch(this.getHref(''))
          .then((data) => {
            data.forEach((d) => {
              this.add(d.id, d.x, d.y, d.z, d.cage_id, d.operation_id, d.hidden, d.name, d.ts);
            });
          })
          .catch((err) => {
            console.log(err);
            // throw Error(err.toString());
          });
      }),
    });
  }
}

export default PointsOfInterestStore;
