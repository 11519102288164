import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';

// import rov_image from '../assets/images/ROV_small.png';
import './ReadoutArea.css';
import { THeadSpan, TRow } from './Table.js';

class RovPos extends Component {
  constructor() {
    super();
    this.state = { checked: false };
  }
  render() {
    const { x, y, z, guidelines, setGuidelines } = this.props;
    const leftright = x < 0 ? 'Left' : 'Right';
    return (
      <table className="readout-area left-side " style={{ bottom: '3em', left: '1em' }}>
        <THeadSpan>
          {/* <Image src={rov_image} size="small" /> */}
          Position
        </THeadSpan>
        <tbody>
          <TRow title="Across" value={y.toFixed(1) + ' m'} />
          <TRow title={leftright} value={Math.abs(x).toFixed(1) + ' m'} />
          <TRow title="Depth" value={(-z).toFixed(1) + ' m'} />
          <TRow
            title="Guides"
            value={
              <Checkbox
                toggle
                onChange={() => {
                  setGuidelines(!guidelines);
                }}
                checked={guidelines}
              />
            }
          />
        </tbody>
      </table>
    );
  }
}

export default RovPos;
