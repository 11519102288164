import * as docx from 'docx';

const tableHeaderShading = {
  type: docx.ShadingType.SOLID,
  color: '1f3864',
  //fill: "1f3864",
};

const topBorderOnly = {
  top: {
    style: docx.BorderStyle.SINGLE,
    size: 1,
    color: 'auto',
  },
  bottom: {
    style: docx.BorderStyle.NIL,
    size: 1,
    color: 'auto',
  },
  left: {
    style: docx.BorderStyle.NIL,
    size: 1,
    color: 'auto',
  },
  right: {
    style: docx.BorderStyle.NIL,
    size: 1,
    color: 'auto',
  },
};

const bottomBorderOnly = {
  top: {
    style: docx.BorderStyle.NIL,
    size: 1,
    color: 'auto',
  },
  bottom: {
    style: docx.BorderStyle.SINGLE,
    size: 1,
    color: 'auto',
  },
  left: {
    style: docx.BorderStyle.NIL,
    size: 1,
    color: 'auto',
  },
  right: {
    style: docx.BorderStyle.NIL,
    size: 1,
    color: 'auto',
  },
};

const ousideBorder = {
  top: {
    style: docx.BorderStyle.SINGLE,
    size: 1,
    color: 'auto',
  },
  bottom: {
    style: docx.BorderStyle.SINGLE,
    size: 1,
    color: 'auto',
  },
  left: {
    style: docx.BorderStyle.SINGLE,
    size: 1,
    color: 'auto',
  },
  right: {
    style: docx.BorderStyle.SINGLE,
    size: 1,
    color: 'auto',
  },
};

const noBorder = {
  top: {
    style: docx.BorderStyle.NIL,
    size: 1,
    color: 'auto',
  },
  bottom: {
    style: docx.BorderStyle.NIL,
    size: 1,
    color: 'auto',
  },
  left: {
    style: docx.BorderStyle.NIL,
    size: 1,
    color: 'auto',
  },
  right: {
    style: docx.BorderStyle.NIL,
    size: 1,
    color: 'auto',
  },
};

const fullWidth = {
  size: 100,
  type: docx.WidthType.AUTO,
};

const spacingMore = {
  after: docx.convertMillimetersToTwip(0.5),
  before: docx.convertMillimetersToTwip(0.5),
};

export { topBorderOnly, bottomBorderOnly, ousideBorder, noBorder, fullWidth, spacingMore, tableHeaderShading };
