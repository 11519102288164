import React from 'react';
import * as ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

import App from './App';
// FIX THIS!!! SemanticUI CSS v2.4.0 has an error
// Follow issue: https://github.com/Semantic-Org/Semantic-UI/issues/7073
// When issue are fixed:
//  1. update/install new version "semantic-ui-css"
//  2. delete /assets/semantic.2.4.0/
//  3. Switch the import of semantic.min.css below:
// import 'semantic-ui-css/semantic.min.css';
import './assets/semantic.2.4.0/semantic.min.css';
import './assets/threeGui.css';
import './index.css';

ReactDOM.render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.getElementById('root')
);
