import { observer, inject } from 'mobx-react';
import React, { Component } from 'react';
import { Message, Popup, Button } from 'semantic-ui-react';

const ShowWarning = ({ message, color }) => (
  <Message
    icon="exclamation"
    style={{ backgroundColor: color, color: 'rgba(255,255,255,1)', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
    content={<strong>{message}</strong>}
  />
);

const severityToColorMapping = {
  warning: '#fbbd08', //"#f5a503",
  error: '#f02101',
};

const WarningMessages = inject('warningsStore')(
  observer(
    class WarningMessages extends Component {
      severityToColor = (severity) => {
        return severityToColorMapping[severity] || '#2185d0';
      };
      handleClose = () => {
        console.log('handle close');
      };
      render() {
        const messages = this.props.warningsStore.getMasked();
        //const messages = this.props.warningsStore.allVisible
        if (messages.length < 1) {
          return <span />;
        }
        // Messages are sorted with the most important message first
        const severity = messages[0].severity;
        let color = 'blue';
        if (severity === 'warning') {
          color = 'yellow';
        }
        if (severity === 'error') {
          color = 'red';
        }
        return (
          <Popup
            trigger={<Button color={color} icon="announcement" content={'Errors (' + messages.length + ')'} />}
            content={
              <div style={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
                {messages.map((d) => (
                  <ShowWarning
                    key={d.id}
                    message={d.message}
                    color={this.severityToColor(d.severity)}
                    onDismiss={d.dismiss}
                  />
                ))}
              </div>
            }
            on="click"
            position="bottom left"
            flowing
          />
        );
      }
    }
  )
);

export default WarningMessages;
