import React, { Component } from 'react';

import './ReadoutArea.css';
import { THeadSpan, TRow } from './Table.js';

class InfoPanel extends Component {
  render() {
    const owner = this.props.owner;
    const site = this.props.site;
    const cage = this.props.cage;
    return (
      <table className="readout-area left-side " style={{ bottom: '23em', left: '1em' }}>
        <THeadSpan>Info</THeadSpan>
        <tbody>
          {/*<TRow title="Current Speed" value={currentSpeed + " " + String.fromCharCode(0x33A1)+"/h"}/>
          <TRow title="Avg. Speed" value={avgSpeed + " " + String.fromCharCode(0x33A1)+"/h"}/>*/}
          <TRow title="Owner" value={owner} />
          <TRow title="Site" value={site} />
          <TRow title="Cage" value={cage} />
        </tbody>
      </table>
    );
  }
}

export default InfoPanel;
