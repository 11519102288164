import { upperFirst } from 'lodash/fp';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Radio,
  Modal,
  Dropdown,
  Input,
  Card,
  Checkbox,
  Tab,
  Grid,
  Table,
  Form,
  Icon,
  Transition,
  Confirm,
  List,
  Image,
  GridRow,
} from 'semantic-ui-react';

import { ShowDockingIllustration } from '../Components/ShowDocking';
import rov_image from '../assets/images/ROV_default.png';
import fnc8_image from '../assets/images/ROV_small.png';
import cage_image from '../assets/images/cage.png';
import track_line from '../assets/images/track_line.png';
import track_tile from '../assets/images/track_tile.png';
import userLogo from '../lib/userlogo';
import './Operate.css';

const Operate = (props) => {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [sites, setSites] = useState([]);
  const [owners, setOwners] = useState([]);
  const [cages, setCages] = useState([]);

  const refresh = () => {
    setOwners([]);
    setSites([]);
    setCages([]);
    fetch('/api/v1/owner/').then((res) =>
      res.json().then((owners) => {
        setOwners(owners);
        owners.forEach((owner) => {
          fetch('/api/v1/owner/' + owner.id + '/site/').then((res) =>
            res.json().then((sites) => {
              setSites((currentSites) => [...currentSites, ...sites]);

              sites.forEach((site) => {
                fetch('/api/v1/owner/' + owner.id + '/site/' + site.id + '/cage/').then((res) =>
                  res.json().then((cages) => {
                    setCages((currentCages) => [...currentCages, ...cages]);
                  })
                );
              });
            })
          );
        });
      })
    );
  };

  useEffect(() => {
    refresh();
  }, []);

  const panes = [
    {
      menuItem: 'Operations',
      render: () => (
        <Tab.Pane>
          <OperateTab {...props} setSidePanelOpen={setSidePanelOpen} sites={sites} cages={cages} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Settings',
      render: () => (
        <Tab.Pane>
          <SettingsTab {...props} />
        </Tab.Pane>
      ),
    },

    {
      menuItem: 'Cages',
      render: () => (
        <Tab.Pane>
          <CagesTab owners={owners} sites={sites} cages={cages} refresh={refresh} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Sites',
      render: () => (
        <Tab.Pane>
          <SitesTab owners={owners} sites={sites} cages={cages} refresh={refresh} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Owners',
      render: () => (
        <Tab.Pane>
          <OwnersTab owners={owners} sites={sites} refresh={refresh} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div data-html2canvas-ignore>
      <Button
        style={{ position: 'absolute', zIndex: 2000, top: '16px', right: '16px' }}
        size="big"
        onClick={() => {
          setSidePanelOpen(!sidePanelOpen);
        }}
        color="green"
      >
        <Icon name={sidePanelOpen ? 'close' : 'sidebar'} />
        Menu
      </Button>
      <Transition.Group animation="slide left" duration={500}>
        {sidePanelOpen && (
          <div className="operation">
            <Tab panes={panes} defaultActiveIndex={0} />
          </div>
        )}
      </Transition.Group>
    </div>
  );
};

const SettingsTab = ({ logoHash, setLogoHash, preferences }) => {
  const uploadLogo = (event) => {
    if (event.target?.files?.length) {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      fetch('/upload', {
        method: 'POST',
        body: formData,
      }).then(() => {
        setLogoHash(Math.random());
      });
    }
  };

  return (
    <Card.Group style={{ minHeight: '325px' }}>
      <Card>
        <Card.Content>
          <Card.Header>Logo</Card.Header>
          <Card.Description>
            <div className="logo-area">
              <img
                style={{ maxWidth: '220px' }}
                src={userLogo + '?' + logoHash}
                alt="Logo"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.style.visibility = 'hidden';
                }}
              />
            </div>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          {/* encType="multipart/form-data" */}
          <Form>
            <label htmlFor="hidden-new-file" className="ui button fluid">
              Upload logo
            </label>
            <Input type="file" id="hidden-new-file" style={{ display: 'none' }} onChange={uploadLogo} />
          </Form>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content>
          <Card.Header>Vehicle</Card.Header>
          <Card.Description textAlign="center">
            {
              <Image
                src={preferences.rov_model === 'fnc8' ? fnc8_image : rov_image}
                size="tiny"
                style={{ marginTop: '50px', marginBottom: '50px' }}
              />
            }
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button.Group fluid>
            <Button
              active={preferences.rov_model === 'fnc8'}
              onClick={() => {
                preferences.modifyRov({
                  rov_model: 'fnc8',
                });
              }}
            >
              FNC8
            </Button>
            <Button.Or />
            <Button
              active={preferences.rov_model === 'other'}
              onClick={() => {
                preferences.modifyRov({
                  rov_model: 'other',
                });
              }}
            >
              ROV
            </Button>
          </Button.Group>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content>
          <Card.Header>Tracking</Card.Header>
          <Card.Description textAlign="center">
            {<Image src={preferences.wash_tile_type === 'tiles' ? track_tile : track_line} size="tiny" />}
            {preferences.wash_tile_type === 'tiles' && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    width={5}
                    type="number"
                    label="Width"
                    value={preferences.wash_tile_width}
                    onChange={(e, { value }) => {
                      preferences.modifyRov({
                        wash_tile_width: Number(value),
                      });
                    }}
                  />
                  <Form.Input
                    width={5}
                    type="number"
                    label="Height"
                    value={preferences.wash_tile_height}
                    onChange={(e, { value }) => {
                      preferences.modifyRov({
                        wash_tile_height: Number(value),
                      });
                    }}
                  />
                  <Form.Input
                    width={5}
                    type="number"
                    label="Thickness"
                    value={preferences.wash_tile_thickness}
                    onChange={(e, { value }) => {
                      preferences.modifyRov({
                        wash_tile_thickness: Number(value),
                      });
                    }}
                  />
                </Form.Group>
              </Form>
            )}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button.Group fluid>
            <Button
              active={preferences.wash_tile_type === 'tiles'}
              onClick={() => {
                preferences.modifyRov({
                  wash_tile_type: 'tiles',
                });
              }}
            >
              Tiles
            </Button>
            <Button.Or />
            <Button
              active={preferences.wash_tile_type !== 'tiles'}
              onClick={() => {
                preferences.modifyRov({
                  wash_tile_type: 'traceline',
                });
              }}
            >
              Line
            </Button>
          </Button.Group>
        </Card.Content>
      </Card>
    </Card.Group>
  );
};

const OperateTab = (props) => {
  const { preferences, cfg, setSidePanelOpen, setRecording } = props;
  const [dockingSide, setDockingSide] = useState();
  const [heading, setHeading] = useState(0);

  const startOperation = () => {
    const operation = {
      cage_id: cfg.cage.id,
      site_id: cfg.site.id,
      owner_id: cfg.owner.id,
      is_starboard: dockingSide === 'Starboard' ? true : false,
      dock_angle: heading,
      name: moment().format('YYYY-MM-DD @ HH:mm:ss'),
      start_time: moment().format('HH:mm'),
      end_time: moment().format('HH:mm'),
      duration: '00:00',
      bio_fauling: cfg.bioFauling,
      pen_shape: cfg.penShape,
      dead_fish: cfg.deadFish,
    };

    fetch('/api/v1/operation/', {
      method: 'POST',
      body: JSON.stringify(operation),
    })
      .then((response) => {
        if (response.status === 201) {
          return fetch(response.headers.get('Location'));
        } else if (response.status === 400) {
          alert('Unable to add Operation. ' + 'Please check the data is correct');
        } else {
          throw Error('Unable to add Operation: ' + response.statusText);
        }
      })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 400) {
          var error_response = { status: 400 };
          return error_response;
        } else {
          throw Error('Unable to fetch newly added operation: ' + response.statusText);
        }
      })
      .then((data) => {
        cfg.operation = {
          ...operation,
          id: data.id,
          text: data.name,
        };
        cfg.changeModeTo('Active');
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const updateSetting = (e, { option, value }) => {
    if (option !== 'operation') {
      const setting = {};
      setting[option + '_id'] = value?.toString();
      preferences.postPreferences(setting);
    }
    cfg.updateConfig(option, value);
  };

  useEffect(() => {
    setDockingSide((dockingSide) => {
      return dockingSide ? dockingSide : preferences.docking_side ? upperFirst(preferences.docking_side) : undefined;
    });
  }, [preferences.docking_side]);

  return (
    <>
      <Modal.Content>
        <Card.Group>
          <Operation {...props} updateSetting={updateSetting} />
          <OwnerSiteCageSelection preferences={preferences} cfg={cfg} updateSetting={updateSetting} />
          <Docking
            updateSetting={updateSetting}
            dockingSide={dockingSide}
            setDockingSide={setDockingSide}
            heading={heading}
            setHeading={setHeading}
            {...props}
          />
        </Card.Group>
        <div style={{ paddingTop: '20px', textAlign: 'right' }}>
          {cfg.mode === 'Active' && (
            <Button
              color="red"
              onClick={() => {
                const operation = {
                  ...cfg.operation,
                  bio_fauling: cfg.bioFauling,
                  pen_shape: cfg.penShape,
                  dead_fish: cfg.deadFish,
                  end_time: moment().format('HH:mm'),
                  duration: moment.utc(moment().diff(moment(cfg.operation.start_time, 'HH:mm'))).format('HH:mm:ss'),
                };

                fetch('/api/v1/operation/' + operation.id, {
                  method: 'PATCH',
                  body: JSON.stringify(operation),
                })
                  .then((response) => {
                    if (response.status === 204) {
                      cfg.changeModeTo('Idle');
                    } else {
                      alert('Unable to end operation\nServer message: ' + response.statusText);
                      throw Error('Unable to end operation');
                    }
                  })
                  .catch((error) => {
                    console.log(error.message);
                  });
              }}
            >
              Stop operation
            </Button>
          )}

          {cfg.operation?.id && (
            <Button
              content="View"
              onClick={() => {
                if (cfg.mode === 'Idle') cfg.changeModeTo('View');
                setSidePanelOpen(false);
              }}
            />
          )}

          {cfg.mode !== 'Active' && (
            <Button
              className="rounded"
              content={cfg.operation?.id ? 'Continue' : 'Start'}
              labelPosition="right"
              icon="play"
              positive
              disabled={(cfg.operation === 'new' && cfg.cage?.id) || cfg.operation?.id ? false : true}
              onClick={() => {
                setRecording(true);
                if (cfg.operation === 'new') {
                  startOperation();
                } else {
                  cfg.changeModeTo('Active');
                }
                setSidePanelOpen(false);
              }}
            />
          )}
        </div>
      </Modal.Content>
    </>
  );
};

const Operation = ({ cfg, updateSetting, sites, cages, setShoot }) => {
  const { owner, site, cage, operation, mode, changeModeTo } = cfg;
  const [activeButton, setActiveButton] = useState();
  const [filter, setFilter] = useState(true);
  const [open, setOpen] = useState(false);
  const [filterClick, setFilterClick] = useState(false);
  const [reportFormat, setReportFormat] = useState('pdf');
  const [operationOptions, setOperationOptions] = useState([]);

  useEffect(() => {
    fetch('/api/v1/operation/').then((response) => {
      response.json().then((operationOptions) => setOperationOptions(operationOptions));
    });
  }, []);

  return (
    <Card>
      <Card.Content>
        <Card.Header>Operation</Card.Header>
        <Card.Description>
          {mode !== 'Active' && (
            <Button.Group fluid style={{ marginBottom: '10px' }}>
              <Button
                positive={operation === 'new' && activeButton === 'new'}
                onClick={() => {
                  if (mode === 'View') changeModeTo('Idle');
                  updateSetting(undefined, { value: 'new', option: 'operation' });
                  setActiveButton('new');
                }}
              >
                New
              </Button>
              <Button.Or />
              <Button
                positive={activeButton === 'previous' || (operation?.id && mode !== 'Active')}
                onClick={() => {
                  setActiveButton('previous');
                  if (operation === 'new') {
                    updateSetting(undefined, { value: undefined, option: 'operation' });
                  }
                }}
              >
                Previous
              </Button>
            </Button.Group>
          )}
          {(activeButton === 'previous' || operation?.id) && (
            <Dropdown
              disabled={mode === 'Active'}
              closeOnBlur
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onBlur={() => {
                if (!filterClick) {
                  setOpen(false);
                }
                setFilterClick(false);
              }}
              fluid
              className="selection dropdown wl-selection"
              upward={false}
              placeholder="Choose previous operation"
              text={operation?.id ? operation.name : operation === 'new' ? 'New operation' : null}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setFilterClick(true);
                    setFilter(!filter);
                  }}
                  content={<Checkbox toggle checked={filter} style={{ zIndex: '-1' }} label="Filter" />}
                />

                <Dropdown.Divider />

                {operationOptions
                  .filter((option) => {
                    if (filter) {
                      if (owner?.id && option.owner_id !== owner.id) return false;
                      if (site?.id && option.site_id !== site.id) return false;
                      if (cage?.id && option.cage_id !== cage.id) return false;
                    }
                    return true;
                  })
                  .map((option) => (
                    <Dropdown.Item
                      key={option.id}
                      value={option.id}
                      content={
                        <>
                          <span>
                            {sites?.find((site) => site.id === option.site_id)?.name}
                            {', '}
                            {cages?.find((cage) => cage.id === option.cage_id)?.name}
                          </span>
                          <br />
                          <span>{moment(option.name).format('DD-MM-YY HH:mm')}</span>
                        </>
                      }
                      option="operation"
                      onClick={(e, v) => {
                        setOpen(false);
                        updateSetting(e, v);
                      }}
                    />
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Card.Description>
      </Card.Content>
      {operation?.id && mode !== 'Active' && (
        <Card.Content>
          <Form>
            <Form.Field>
              <Radio
                label="PDF"
                name="radioGroup"
                // value='this'
                checked={reportFormat === 'pdf'}
                onChange={() => setReportFormat('pdf')}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label="DOCX"
                name="radioGroup"
                // value='that'
                checked={reportFormat === 'doc'}
                onChange={() => setReportFormat('doc')}
              />
            </Form.Field>
          </Form>
          <Button fluid disabled={!reportFormat} onClick={() => setShoot(reportFormat)} style={{ marginTop: '30px' }}>
            Download new report
          </Button>
        </Card.Content>
      )}
    </Card>
  );
};

const OwnerSiteCageSelection = ({ preferences, updateSetting, cfg }) => {
  const { owner, site, cage, operation } = cfg;
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [cageOptions, setCageOptions] = useState([]);

  useEffect(() => {
    if (operation?.id && operation.owner_id) {
      preferences.postPreferences({
        owner_id: operation.owner_id.toString(),
        site_id: operation.site_id.toString(),
        cage_id: operation.cage_id.toString(),
      });
    }
  }, [operation]);

  useEffect(() => {
    fetch('/api/v1/owner/').then((response) => {
      response.json().then((ownerOptions) =>
        setOwnerOptions(
          ownerOptions.map((ownerOption) => {
            if (!operation?.owner_id && preferences.owner_id && parseInt(preferences.owner_id) === ownerOption.id) {
              updateSetting(undefined, { option: 'owner', value: ownerOption.id });
            }
            return ownerOption;
          })
        )
      );
    });
  }, []);

  useEffect(() => {
    if (owner?.id) {
      fetch('/api/v1/owner/' + owner.id.toString() + '/site/').then((response) => {
        response.json().then((siteOptions) =>
          setSiteOptions(
            siteOptions.map((siteOption) => {
              if (!operation?.site_id && preferences.site_id && parseInt(preferences.site_id) === siteOption.id) {
                updateSetting(undefined, { option: 'site', value: siteOption.id });
              }
              return siteOption;
            })
          )
        );
      });
    }
  }, [owner]);

  useEffect(() => {
    if (owner?.id && site?.id) {
      fetch('/api/v1/owner/' + owner.id.toString() + '/site/' + site.id.toString() + '/cage/').then((response) => {
        response.json().then((cageOptions) =>
          setCageOptions(
            cageOptions.map((cageOption) => {
              if (!operation?.cage_id && preferences.cage_id && parseInt(preferences.cage_id) === cageOption.id) {
                updateSetting(undefined, { option: 'cage', value: cageOption.id });
              }
              return cageOption;
            })
          )
        );
      });
    }
  }, [site]);

  return (
    <Card>
      <Card.Content>
        <Card.Header>Cage</Card.Header>
        <Card.Description>
          <Dropdown
            disabled={operation?.id ? true : false}
            clearable
            option={'owner'}
            onChange={updateSetting}
            options={ownerOptions.map((option) => ({
              key: option.id,
              text: option.name,
              value: option.id,
            }))}
            placeholder={'Choose Owner'}
            selection
            value={owner?.id ? owner.id : operation?.owner_id ? operation.owner_id : null}
          />

          <Dropdown
            disabled={operation?.id ? true : false}
            clearable
            option={'site'}
            onChange={updateSetting}
            options={siteOptions.map((option) => ({
              key: option.id,
              text: option.name,
              value: option.id,
            }))}
            placeholder={'Choose Site'}
            selection
            value={site?.id ? site.id : operation?.site_id ? operation.site_id : null}
          />
          <Dropdown
            disabled={operation?.id ? true : false}
            clearable
            option={'cage'}
            onChange={updateSetting}
            options={cageOptions.map((option) => ({
              key: option.id,
              text: option.name,
              value: option.id,
            }))}
            placeholder={'Choose Cage'}
            selection
            value={cage?.id ? cage.id : operation?.cage_id ? operation.cage_id : null}
          />
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

const Docking = ({ dockingSide, setDockingSide, heading, setHeading, updateSetting, cfg }) => {
  const { operation } = cfg;
  return (
    <Card>
      <Card.Content>
        <Card.Header>Docking</Card.Header>
        <Card.Description>
          <Dropdown
            disabled={operation?.id ? true : false}
            placeholder="Docking side"
            selection
            value={dockingSide}
            options={[
              {
                text: 'Starboard',
                value: 'Starboard',
              },
              {
                text: 'Port',
                value: 'Port',
              },
            ]}
            onChange={(e, { value }) => {
              updateSetting(undefined, { value: value.toLowerCase(), option: 'docking_side' });
              setDockingSide(value);
            }}
            style={{ position: 'relative' }}
          />
        </Card.Description>
        <Card.Description style={{ paddingTop: '20px', marginBottom: '-20px' }}>
          <p>Vessel compass direction</p>
          <Input
            disabled={operation?.id ? true : false}
            min={0}
            max={359}
            placeholder="Heading"
            type="number"
            onChange={(e, { value }) => {
              setHeading(Number(value));
            }}
            value={operation?.dock_angle ? operation.dock_angle : heading}
          />
          <Input
            style={{ bottom: '-15px' }}
            disabled={operation?.id ? true : false}
            min={0}
            max={359}
            onChange={(e, { value }) => {
              setHeading(Number(value));
            }}
            type="range"
            value={operation?.dock_angle ? operation.dock_angle : heading}
          />
        </Card.Description>
      </Card.Content>
      <ShowDockingIllustration
        is_starboard={operation?.is_starboard ? operation.is_starboard : dockingSide === 'Starboard' ? true : false}
        dock_angle={operation?.dock_angle ? parseInt(operation.dock_angle) : parseInt(heading)}
      />
    </Card>
  );
};

const OwnersTab = ({ owners, sites, refresh }) => {
  const [confirm, setConfirm] = useState(false);
  const [newOwner, setNewOwner] = useState();
  const [editOwner, setEditOwner] = useState();

  return (
    <List divided className="wl-list">
      <List.Item key="add-owner">
        <List.Content>
          {newOwner?.name === undefined ? (
            <Button
              icon
              onClick={() => setNewOwner({ name: '' })}
              size="mini"
              style={{ width: '200px', marginBottom: '20px' }}
            >
              <Icon name="add" />
              Add Owner
            </Button>
          ) : (
            <Form>
              <Button
                disabled={newOwner.name.length < 2}
                icon
                size="mini"
                color="blue"
                onClick={() =>
                  fetch('/api/v1/owner/', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newOwner),
                  }).then((res) => {
                    if (!res.ok) {
                      res.json().then((json) => {
                        console.log('json', json);
                        alert(json.detail);
                      });
                    } else {
                      setNewOwner(undefined);
                      refresh();
                    }
                  })
                }
                style={{ position: 'absolute', right: '100px', bottom: '0' }}
              >
                <Icon name="add" /> Add
              </Button>
              <Button
                size="mini"
                style={{ position: 'absolute', right: '0', bottom: '0' }}
                onClick={() => setNewOwner(null)}
              >
                <Icon name="cancel" /> Cancel
              </Button>

              <Form.Group>
                <Form.Input
                  label="Add owner"
                  placeholder="Name"
                  value={newOwner?.name}
                  onChange={(e, { value }) =>
                    setNewOwner((currentValues) => ({
                      ...currentValues,
                      name: value,
                    }))
                  }
                />
              </Form.Group>
            </Form>
          )}
        </List.Content>
      </List.Item>
      {owners.map((owner) => (
        <List.Item key={owner.id}>
          <List.Content floated="right">
            <Button
              size="mini"
              icon
              color={editOwner?.ownerId === owner.id ? 'green' : 'blue'}
              onClick={() =>
                editOwner?.ownerId === owner.id
                  ? fetch('/api/v1/owner/' + editOwner.ownerId, {
                      method: 'PATCH',
                      body: JSON.stringify(editOwner),
                    })
                      .then((res) => {
                        if (!res.ok) {
                          res.json().then((json) => {
                            console.log('json', json);
                            alert(json.detail);
                          });
                        } else {
                          refresh();
                          setEditOwner(null);
                        }
                      })
                      .catch((error) => {
                        console.log(error.message);
                      })
                  : setEditOwner({ name: owner.name, ownerId: owner.id })
              }
            >
              <Icon name={editOwner?.ownerId === owner.id ? 'save' : 'pencil'} />{' '}
              {editOwner?.ownerId === owner.id ? 'Save' : 'Edit'}
            </Button>
            {editOwner?.ownerId === owner.id && (
              <Button size="mini" icon onClick={() => setEditOwner(null)}>
                <Icon name="cancel" /> Cancel
              </Button>
            )}
            {editOwner?.ownerId !== owner.id && (
              <Button size="mini" icon onClick={() => setConfirm({ ownerId: owner.id })}>
                <Icon name="trash" /> Delete
              </Button>
            )}
            <Confirm
              size="tiny"
              style={{ color: 'black' }}
              header="Delete owner?"
              content="Any sites, cages and operations, registered with this owner, will also be lost!"
              open={confirm ? true : false}
              onCancel={() => setConfirm(false)}
              onConfirm={() => {
                fetch('/api/v1/owner/' + confirm.ownerId, {
                  method: 'DELETE',
                });
                refresh();
                setConfirm(false);
              }}
            />
          </List.Content>
          <List.Icon size="large" name="building outline" />
          <List.Content>
            {owner.id === editOwner?.ownerId ? (
              <Form.Input
                // label="Add owner"
                autoFocus
                placeholder="Name"
                value={editOwner?.name}
                onChange={(e, { value }) =>
                  setEditOwner((currentValues) => ({
                    ...currentValues,
                    name: value,
                  }))
                }
              />
            ) : (
              <List.Header>{owner.name}</List.Header>
            )}{' '}
            {sites.filter((site) => site.links.owner.id === owner.id).length} sites
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};

const SitesTab = ({ owners, sites, cages, refresh }) => {
  const [confirm, setConfirm] = useState(false);
  const [newSite, setNewSite] = useState({ owner: { id: undefined, name: '' } });
  const [editSite, setEditSite] = useState();

  return (
    <List divided className="wl-list">
      <List.Item key="add-site">
        <List.Content>
          {newSite?.name === undefined ? (
            <Button
              icon
              onClick={() => setNewSite({ name: '', owner: { id: undefined, name: '' } })}
              size="mini"
              style={{ width: '200px', marginBottom: '20px' }}
            >
              <Icon name="add" />
              Add site
            </Button>
          ) : (
            <Form>
              <Button
                size="mini"
                disabled={newSite.name.length < 2 || !newSite.owner.id}
                icon
                color="green"
                onClick={() =>
                  fetch('/api/v1/owner/' + newSite.owner.id + '/site/', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newSite),
                  }).then((res) => {
                    if (!res.ok) {
                      res.json().then((json) => {
                        console.log('json', json);
                        alert(json.detail);
                      });
                    } else {
                      setNewSite({ owner: { id: undefined, name: '' } });
                      refresh();
                    }
                  })
                }
                style={{ position: 'absolute', right: '100px', bottom: '0' }}
              >
                <Icon name="add" /> Add
              </Button>
              <Button
                size="mini"
                style={{ position: 'absolute', right: '0', bottom: '0' }}
                onClick={() => setNewSite({ owner: { id: undefined, name: '' } })}
              >
                <Icon name="cancel" /> Cancel
              </Button>
              <Form.Group>
                <Form.Input
                  autoFocus
                  label="Add site"
                  placeholder="Name"
                  value={newSite?.name}
                  onChange={(e, { value }) =>
                    setNewSite((currentValues) => ({
                      ...currentValues,
                      name: value,
                    }))
                  }
                />

                <Form.Input
                  placeholder="Owner"
                  list="owners"
                  icon={
                    <Icon
                      name="close"
                      link
                      onClick={() =>
                        setNewSite((currentValues) => ({
                          ...currentValues,
                          owner: { id: undefined, name: '' },
                        }))
                      }
                    />
                  }
                  value={newSite.owner?.name ? newSite.owner.name : ''}
                  onChange={(e, { value }) =>
                    setNewSite((currentValues) => ({
                      ...currentValues,
                      owner: {
                        id: owners?.find((owner) => owner.name === value)?.id,
                        name: value,
                      },
                    }))
                  }
                />
                <datalist id="owners">
                  {owners.map((owner) => (
                    <option key={owner.id} value={owner.name} />
                  ))}
                </datalist>
              </Form.Group>
            </Form>
          )}
        </List.Content>
      </List.Item>
      {owners.map((owner) =>
        sites
          .filter((site) => site.links.owner.id === owner.id)
          .map((site) => (
            <List.Item key={site.id}>
              <List.Content floated="right">
                <Button
                  size="mini"
                  icon
                  color={editSite?.siteId === site.id ? 'green' : 'blue'}
                  onClick={() =>
                    editSite?.siteId === site.id
                      ? fetch('/api/v1/owner/' + editSite.ownerId + '/site/' + editSite.siteId, {
                          method: 'PATCH',
                          body: JSON.stringify(editSite),
                        })
                          .then((res) => {
                            if (!res.ok) {
                              res.json().then((json) => {
                                console.log('json', json);
                                alert(json.detail);
                              });
                            } else {
                              refresh();
                              setEditSite(null);
                            }
                          })
                          .catch((error) => {
                            console.log(error.message);
                          })
                      : setEditSite({ name: site.name, siteId: site.id, ownerId: owner.id })
                  }
                >
                  <Icon name={editSite?.siteId === site.id ? 'save' : 'pencil'} />{' '}
                  {editSite?.siteId === site.id ? 'Save' : 'Edit'}
                </Button>
                {editSite?.siteId === site.id && (
                  <Button size="mini" icon onClick={() => setEditSite(null)}>
                    <Icon name="cancel" /> Cancel
                  </Button>
                )}
                {editSite?.siteId !== site.id && (
                  <Button size="mini" icon onClick={() => setConfirm({ siteId: site.id, ownerId: owner.id })}>
                    <Icon name="trash" /> Delete
                  </Button>
                )}
                <Confirm
                  size="tiny"
                  style={{ color: 'black' }}
                  header="Delete Site?"
                  content="Any cages and operations, registered at this site, will also be lost!"
                  open={confirm ? true : false}
                  onCancel={() => setConfirm(false)}
                  onConfirm={() => {
                    fetch('/api/v1/owner/' + confirm.ownerId + '/site/' + confirm.siteId, {
                      method: 'DELETE',
                    });
                    refresh();
                    setConfirm(false);
                  }}
                />
              </List.Content>
              <List.Icon size="big" name="map marker alternate" />
              <List.Content>
                {site.id === editSite?.siteId ? (
                  <Form.Input
                    // label="Add site"
                    autoFocus
                    placeholder="Name"
                    value={editSite?.name}
                    onChange={(e, { value }) =>
                      setEditSite((currentValues) => ({
                        ...currentValues,
                        name: value,
                      }))
                    }
                  />
                ) : (
                  <List.Header>{site.name}</List.Header>
                )}
                {owner.name}, {cages.filter((cage) => cage.links.site.id === site.id).length} cages
              </List.Content>
            </List.Item>
          ))
      )}
    </List>
  );
};

const CagesTab = ({ owners, sites, cages, refresh }) => {
  const [selectedCage, setSelectedCage] = useState();
  const [confirmDeleteCage, setConfirmDeleteCage] = useState(false);

  const getSite = (cage) => {
    const s1 = sites?.find((site) => site.id === cage?.site?.id);
    const s2 = sites?.find((site) => site.id === cage.links?.site?.id);
    return s1 ? s1 : s2;
  };
  const getOwner = (cage) => {
    return owners?.find((owner) => owner.id === cage?.owner?.id || owner.id === getSite(cage)?.links.owner.id);
  };

  return (
    <Grid columns={2}>
      <Grid.Column key={'list'} style={{ maxHeight: '500px', overflow: 'auto' }}>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Cage</Table.HeaderCell>
              <Table.HeaderCell>Site</Table.HeaderCell>
              <Table.HeaderCell>Owner</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {cages
              .sort((a, b) => (a.id > b.id ? 1 : -1))
              .map((cage) => (
                <Table.Row
                  key={cage.id}
                  onClick={() => setSelectedCage({ ...cage, owner: getOwner(cage), site: getSite(cage) })}
                  active={selectedCage?.id === cage.id}
                >
                  <Table.Cell>{cage.name}</Table.Cell>
                  {/* <Table.Cell>{sites?.find((site) => site.id === cage.links.site.id)?.name}</Table.Cell> */}
                  <Table.Cell>{getSite(cage)?.name}</Table.Cell>
                  <Table.Cell>{getOwner(cage)?.name}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Grid.Column>

      <Grid.Column key={'cage'}>
        {selectedCage ? (
          <Card fluid>
            <Card.Content>
              <Card.Header>{selectedCage.id ? 'Edit cage' : 'Add cage'}</Card.Header>
            </Card.Content>
            <Card.Content>
              <Card.Description>
                <Form>
                  <Form.Input
                    label="Site owner"
                    fluid
                    disabled={selectedCage.id ? true : false}
                    list="owners"
                    icon={
                      <Icon
                        name="close"
                        link
                        onClick={() =>
                          setSelectedCage((currentValues) => ({
                            ...currentValues,
                            owner: { id: undefined, name: '' },
                            site: { id: undefined, name: '' },
                            links: { site: { id: undefined } },
                          }))
                        }
                      />
                    }
                    value={selectedCage.owner?.name ? selectedCage.owner.name : ''}
                    onChange={(e, { value }) =>
                      setSelectedCage((currentValues) => ({
                        ...currentValues,
                        owner: {
                          id: owners?.find((owner) => owner.name === value)?.id,
                          name: value,
                        },
                      }))
                    }
                  />
                  <datalist id="owners">
                    {owners.map((owner) => (
                      <option key={owner.id} value={owner.name} />
                    ))}
                  </datalist>

                  <Form.Input
                    label="Site name"
                    list="sites"
                    disabled={selectedCage.id ? true : false}
                    icon={
                      <Icon
                        name="close"
                        link
                        onClick={() =>
                          setSelectedCage((currentValues) => ({
                            ...currentValues,
                            site: { id: undefined, name: '' },
                            links: { site: { id: undefined } },
                          }))
                        }
                      />
                    }
                    value={selectedCage.site?.name}
                    onChange={(e, { value }) =>
                      setSelectedCage((currentValues) => ({
                        ...currentValues,
                        site: {
                          id: sites?.find((site) => site.name === value)?.id,
                          name: value,
                        },
                        // links: {
                        //   site: {
                        //     id: sites?.find((site) => site.name === value)?.id,
                        //   },
                        // },
                      }))
                    }
                  />
                  <datalist id="sites">
                    {sites
                      .filter(
                        (site) =>
                          site.links.owner.id === owners.find((owner) => owner.name === selectedCage?.owner?.name)?.id
                      )
                      .map((site) => (
                        <option key={site.id} value={site.name} />
                      ))}
                  </datalist>

                  <Form.Input
                    label="Cage name"
                    value={selectedCage.name}
                    onChange={(e, { value }) =>
                      setSelectedCage((currentValues) => ({
                        ...currentValues,
                        name: value,
                      }))
                    }
                  />

                  <div className="field">
                    <label>Cage size</label>
                  </div>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={10}>
                        <Image src={cage_image} style={{ top: '15px' }} />
                      </Grid.Column>
                      <Grid.Column width={6} style={{ marginLeft: '-15px' }}>
                        <GridRow>
                          Circumference
                          <Input
                            style={{ width: '85px' }}
                            type="number"
                            label={{ basic: true, content: 'm' }}
                            labelPosition="right"
                            placeholder="Circumference"
                            value={selectedCage.r_top ? parseInt(selectedCage.r_top * 2 * Math.PI) : ''}
                            onChange={(e, { value }) =>
                              setSelectedCage((currentValues) => ({
                                ...currentValues,
                                r_top: Number(value / 2 / Math.PI),
                              }))
                            }
                          />
                        </GridRow>

                        <GridRow style={{ marginTop: '30px' }}>
                          Netwall height
                          <Input
                            style={{ width: '85px' }}
                            type="number"
                            label={{ basic: true, content: 'm' }}
                            labelPosition="right"
                            placeholder="Netwall height"
                            value={selectedCage.felling_height}
                            onChange={(e, { value }) =>
                              setSelectedCage((currentValues) => ({
                                ...currentValues,
                                felling_height: Number(value),
                              }))
                            }
                          />
                        </GridRow>

                        <GridRow style={{ marginTop: '30px' }}>
                          Total height
                          <Input
                            style={{ width: '85px' }}
                            type="number"
                            label={{ basic: true, content: 'm' }}
                            labelPosition="right"
                            placeholder="Total height"
                            value={selectedCage.depth}
                            onChange={(e, { value }) =>
                              setSelectedCage((currentValues) => ({
                                ...currentValues,
                                depth: Number(value),
                              }))
                            }
                          />
                        </GridRow>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="right">
              <Button size="mini" onClick={() => setSelectedCage(undefined)}>
                Cancel
              </Button>

              {selectedCage.id && (
                <Button size="mini" color="red" onClick={() => setConfirmDeleteCage(true)}>
                  Delete
                </Button>
              )}
              <Confirm
                size="tiny"
                style={{ color: 'black' }}
                header="Delete cage?"
                content="Any operations registered in this cage will also be lost!"
                open={confirmDeleteCage}
                onCancel={() => setConfirmDeleteCage(false)}
                onConfirm={() => {
                  fetch(
                    '/api/v1/owner/' +
                      selectedCage.owner.id +
                      '/site/' +
                      selectedCage.site.id +
                      '/cage/' +
                      selectedCage.id,
                    {
                      method: 'DELETE',
                    }
                  );
                  refresh();
                  setConfirmDeleteCage(false);
                }}
              />
              <Button
                size="mini"
                disabled={!selectedCage.owner || !selectedCage.site ? true : false}
                color="green"
                onClick={() => {
                  fetch(
                    '/api/v1/owner/' +
                      selectedCage.owner.id +
                      '/site/' +
                      selectedCage.site.id +
                      '/cage/' +
                      (selectedCage.id ? selectedCage.id : ''),
                    {
                      method: selectedCage.id ? 'PATCH' : 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(selectedCage),
                    }
                  ).then((res) => {
                    if (!res.ok) {
                      res.json().then((json) => {
                        console.log('json', json);
                        alert(json.detail);
                      });
                    }
                  });
                  refresh();
                }}
              >
                Save
              </Button>
            </Card.Content>
          </Card>
        ) : (
          <Card fluid>
            <Card.Content>
              <Button
                onClick={() => {
                  setSelectedCage({ name: '' });
                }}
                fluid
                size="mini"
              >
                Add cage
              </Button>
            </Card.Content>
          </Card>
        )}
      </Grid.Column>
    </Grid>
  );
};

export { Operate };
