function postFetch(url, data) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic d2F0ZXJsaW5rZWQ6d2F0ZXJsaW5rZWQ=',
    },
    body: JSON.stringify(data),
  });
}

function superFetch(endpoint) {
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic d2F0ZXJsaW5rZWQ6d2F0ZXJsaW5rZWQ=',
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
}

function superFetchHeaders(endpoint, method, data) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Basic d2F0ZXJsaW5rZWQ6d2F0ZXJsaW5rZWQ=',
  };
  return fetch(endpoint, {
    method: method,
    headers: headers,
    body: JSON.stringify(data),
  });
}

export { postFetch, superFetch, superFetchHeaders };
