import { inject, observer } from 'mobx-react';
import moment from 'moment';
// import moment from 'moment';
import React, { useState } from 'react';
import { Table, Button, TextArea, Form, Header, Confirm, Segment } from 'semantic-ui-react';

import './Poi.css';
import './ReadoutArea.css';

const Poi = inject(
  'poiStore',
  'positionStore',
  'cfg'
)(
  observer((props) => {
    const { poiStore, cfg, positionStore, setPoiHover } = props;
    const [edit, setEdit] = useState();
    const [confirmDelete, setConfirmDelete] = useState();
    const [confirmHide, setConfirmHide] = useState();

    // const getDirection = (poi) => {
    //   var rad = Math.atan2(poi.x, poi.y);
    //   var deg = rad * (180 / Math.PI);

    //   return parseInt(deg);
    // };

    return (
      <div
        className="readout-area"
        style={{ bottom: '8em', right: '1em', zIndex: 10, height: '400px', overflow: 'auto' }}
      >
        <Table color="blue" selectable inverted compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={15} colSpan="5">
                Points of interest
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Button
                  icon="add"
                  color="green"
                  onClick={() => {
                    fetch('/api/v1/poi/', {
                      method: 'POST',
                      body: JSON.stringify({
                        name: new moment().format('HH:mm:ss'),
                        x: positionStore.x,
                        y: positionStore.y,
                        z: positionStore.z,
                        cage_id: cfg.operation.cage_id,
                        operation_id: cfg.operation.id,
                        ts: '2018-07-17T10:27:29.699536549+02:10', // Dummy timestamp needs to be here, but the real timestamp is added by the backend.
                      }),
                    }).then(() => {
                      poiStore.fetch();
                    });
                  }}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {poiStore.allVisible?.length ? (
              poiStore.allVisible
                .filter((poi) => poi.cage_id === cfg.operation.cage_id)
                .sort((a, b) => (a.ts > b.ts ? -1 : 1))
                .sort((a) => (a.operation_id !== cfg.operation.id ? 1 : -1))
                .map((poi) => {
                  return (
                    <Table.Row
                      onMouseEnter={() => {
                        setPoiHover(poi.id);
                      }}
                      onMouseLeave={() => {
                        setPoiHover(null);
                      }}
                      key={poi.id}
                      // onClick={() => setSelectedCage({ ...cage, owner: getOwner(cage), site: getSite(cage) })}
                      // active={selectedCage?.id === cage.id}
                    >
                      <Table.Cell width={1} textAlign="center">
                        <div
                          style={{
                            color: poi.operation_id === cfg.operation.id ? 'white' : 'lightgrey',
                            backgroundColor: poi.operation_id === cfg.operation.id ? '#990000' : '#162942',
                            fontWeight: poi.operation_id === cfg.operation.id ? '700' : '400',
                            height: '30px',
                            width: '30px',
                            borderRadius: '30px',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          {poi.id}
                        </div>
                      </Table.Cell>
                      <Table.Cell width={14} colSpan={edit?.id === poi.id ? 4 : 3}>
                        {edit?.id === poi.id ? (
                          <Form>
                            <TextArea
                              size="mini"
                              placeholder="Description..."
                              value={edit.name}
                              onChange={(e, { value }) => {
                                setEdit((edit) => ({ ...edit, name: value }));
                              }}
                            />
                          </Form>
                        ) : (
                          <Header as="h4" inverted>
                            <Header.Content>
                              {poi.name.split(':').length === 3 ? '' : poi.name}
                              <Header.Subheader>
                                <Segment.Inline>
                                  {moment(poi.ts).isBefore(moment(), 'day')
                                    ? moment(poi.ts).format('DD.MM.YYYY HH:mm')
                                    : moment(poi.ts).format('HH:mm')}
                                </Segment.Inline>
                                {/* <Segment.Inline>
                                  <Icon name="map marker alternate" />
                                  {getDirection(poi)}&deg;N, {parseInt(-poi.z)}m depth
                                  <br />
                                  x: {poi.x}
                                  <br />
                                  y: {poi.y}
                                  <br />
                                  calc: {90 + parseInt(cfg.operation.dock_angle) + getDirection(poi)} <br />
                                  dock angle:{cfg.operation.dock_angle} <br />
                                </Segment.Inline> */}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        )}
                      </Table.Cell>

                      <Table.Cell width={1} colSpan={edit?.id === poi.id ? 1 : 2} style={{ width: '1px' }}>
                        {edit?.id === poi.id ? (
                          <>
                            <Button size="mini" icon="cancel" onClick={() => setEdit(null)} />
                            <Button
                              size="mini"
                              icon="save"
                              color="green"
                              onClick={() => {
                                fetch('/api/v1/poi/' + poi.id, {
                                  method: 'PATCH',
                                  body: JSON.stringify(edit),
                                }).then(() => {
                                  poiStore.fetch();
                                  setEdit(edit ? null : poi);
                                });
                              }}
                            />
                          </>
                        ) : (
                          <Button.Group size="mini">
                            <Button
                              icon="pencil"
                              onClick={() => {
                                setEdit(edit ? null : poi);
                              }}
                            />
                            {poi.operation_id === cfg.operation.id ? (
                              <>
                                <Button icon="trash" onClick={() => setConfirmDelete(true)} />
                                <Confirm
                                  size="mini"
                                  open={confirmDelete}
                                  content="Delete POI permanently?"
                                  confirmButton="Yes"
                                  cancelButton="No"
                                  onCancel={() => setConfirmDelete(null)}
                                  onConfirm={() => {
                                    poiStore.deletePersistent(poi.id);
                                    setConfirmDelete(false);
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Button icon="eye slash" onClick={() => setConfirmHide(true)} />
                                <Confirm
                                  size="mini"
                                  open={confirmHide}
                                  content="Hide POI from this operation?"
                                  confirmButton="Yes"
                                  cancelButton="No"
                                  onCancel={() => setConfirmHide(null)}
                                  onConfirm={() => {
                                    console.log(poi);
                                    fetch('/api/v1/poi/' + poi.id, {
                                      method: 'PATCH',
                                      body: JSON.stringify({ ...poi, hidden: true }),
                                    }).then(() => {
                                      poiStore.fetch();
                                      setConfirmHide(false);
                                    });
                                  }}
                                />
                              </>
                            )}
                          </Button.Group>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
            ) : (
              <>None...</>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  })
);

export default Poi;
