import { extendObservable, observable, makeAutoObservable, runInAction } from 'mobx';

class FNCPositionStore {
  constructor() {
    makeAutoObservable(this);
    extendObservable(this, {
      loadingError: '',
      x: 0,
      y: 0,
      z: 0,
      position_valid: false,
      matrix: observable([
        [1, 0, 0],
        [0, 1, 0],
        [0, 0, 1],
      ]),
      isWashing: false,
    });
  }
  fetch() {
    return this.fetchPosition().catch((e) => {
      this.loadingError = e.toString();
    });
  }
  fetchPosition() {
    return fetch('/api/v1/position/acoustic/filtered', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic d2F0ZXJsaW5rZWQ6d2F0ZXJsaW5rZWQ=',
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        runInAction(() => {
          this.x = data.y;
          this.y = data.x;
          this.z = -data.z;
          this.position_valid = data.position_valid;
          this.isWashing = data.is_washing;
          this.loadingError = '';
        });
      })
      .catch((e) => {
        this.loadingError = e.toString();
      });
  }
}

export { FNCPositionStore };
