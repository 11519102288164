import * as docx from 'docx';
import { saveAs } from 'file-saver';

import { createTable2, createTableChildren, createHeaderImages, createFooterTable } from './docxParts';
import { createImage, createParagraph, createHeading } from './docxParts';

const createCageSection = (data) => {
  const name = data['name'] || '';
  const diameter = Math.round(data['dimensions']['r_top'] * 2 * Math.PI).toString() || '';
  const depth_angled = data['dimensions']['felling_height'].toString() || '';
  const depth = data['dimensions']['depth'].toString() || '';

  const rows = [
    ['Cage Name', name],
    ['Cage Top', `${diameter} m`],
    ['Depth to angled pen', `${depth_angled} m`],
    ['Cage depth', `${depth} m`],
  ];
  return createTable2('Cage info', rows);
};

const createDockingInfo = (data) => {
  const side = data['is_starboard'] ? 'Starboard' : 'Port' || '';
  const dock_angle = Math.round(data['dock_angle']).toString() || '';

  const rows = [
    ['Docking side', side],
    ['Heading', `${dock_angle} degrees`],
  ];
  return createTable2('Docking info', rows);
};

const createOperation = (data) => {
  const start = data['start_time'] || '';
  const duration = data['duration'] || '';
  const end = data['end_time'] || '';

  const rows = [
    ['Start time', start],
    ['Duration (hh:mm)', duration],
    ['End time', end],
  ];
  return createTable2('Operation', rows);
};

const createObservation = (data) => {
  const bioFauling = data['bioFauling'] || '';
  const penShape = data['penShape'] || '';
  const deadFish = data['deadFish'] || '';

  const rows = [
    ['Bio Fauling', bioFauling],
    ['Pen Shape', penShape],
    ['Deadfish Liftup/Mort collector', deadFish],
  ];
  return createTable2('Observation', rows);
};

const createPOI = (pois) => {
  const rows = pois.map((d) => [d['name'], d['desc']]);
  return createTable2('Points of interest', rows);
};

const createScreenshots = (images) => {
  const img = images.map((d) => ['', createImage(d)]);
  return createTableChildren('Screenshots', img);
};

const createUserScreenshots = () => {
  const rows = [['', '<add here>']];
  return createTable2('Screenshots POI', rows);
};

const createUserFreeText = () => {
  const rows = [
    ['Weather condition', ''],
    ['Operator', ''],
    ['Service vessel', ''],
    ['Other', ''],
    ['Other', ''],
    ['Other', ''],
  ];
  return createTable2('Free text', rows);
};

const HeadingForPoi = (x, y) => {
  return (360 + Math.round((Math.atan2(x, y) * 180) / Math.PI)) % 360;
};

async function generateDocxReport({ cfg, preferences, poiStore, screenShots }) {
  console.log('generate docx');

  if (!cfg['operation']) {
    console.log('Operation not set. Abort');
    return;
  }

  const data = {
    cage: {
      name: cfg['owner'].name + ', ' + cfg['site'].name + ', ' + cfg['cage'].name,
      dimensions: {
        r_top: cfg['cage'].r_top,
        felling_height: Math.round(cfg['cage'].felling_height),
        depth: cfg['cage'].depth,
      },
    },
    docking: {
      is_starbord: cfg['operation'].is_starboard,
      dock_angle: cfg['operation'].dock_angle,
    },
    operation: {
      start_time: cfg['operation'].start_time,
      duration: cfg['operation'].duration,
      end_time: cfg['operation'].end_time,
    },
    observation: {
      bioFauling: cfg['bioFauling'] || '',
      penShape: cfg['penShape'] || '',
      deadFish: cfg['deadFish'] || '',
    },
    poi: [],
    screenshots: [screenShots.first, screenShots.second, screenShots.third, screenShots.fourth, screenShots.fifth],
  };

  try {
    await poiStore.fetch();
    const relevantPOIs = poiStore.all.filter((d) => d.operation_id === cfg.operation.id);
    const poiList = relevantPOIs.map((poi) => {
      const heading = HeadingForPoi(poi.x, poi.y);
      const depth = -poi.z.toFixed(2);

      return {
        name: poi.name,
        desc: `degrees = ${heading}°, depth = ${depth} m`,
      };
    });
    data['poi'] = poiList;
  } catch (e) {
    console.log('poi error', e);
  }

  const doc = new docx.Document({
    creator: 'Waterlinked CageSense',
    title:
      'Water Linked ' + (preferences?.operation_mode === 'clean' ? 'NetClean GPS Wash' : 'NetInspect GPS') + ' report',
    styles: {
      default: {
        heading1: {
          run: {
            font: 'sans-serif',
            color: '1f3864',
          },
        },
        heading2: {
          run: {
            font: 'sans-serif',
            color: '1f3864',
          },
        },
      },
      paragraphStyles: [
        {
          // Only `name` prop required, `id` not necessary
          name: 'Normal',
          run: {
            font: 'sans-serif',
          },
        },
      ],
    },
    sections: [
      {
        headers: {
          default: new docx.Header({
            children: [createHeaderImages(screenShots['custom_logo'], screenShots['logo'])],
          }),
        },
        footers: {
          default: new docx.Footer({
            children: [createFooterTable()],
          }),
        },
        children: [
          createHeading(
            preferences?.operation_mode === 'inspect'
              ? 'NetInspect GPS Operation Report'
              : 'NetClean GPS Operation Report'
          ),
          createCageSection(data['cage']),
          createParagraph(''),
          createDockingInfo(data['docking']),
          createParagraph(''),
          createOperation(data['operation']),
          createParagraph(''),
          createObservation(data['observation']),
          createParagraph(''),
          createPOI(data['poi']),
          createParagraph(''),
          createScreenshots(data['screenshots']),
          createParagraph(''),
          createParagraph(''),
          createUserScreenshots(),
          createParagraph(''),
          createUserFreeText(),
        ],
      },
    ],
  });

  const filename =
    'WaterLinked-CageSense-report-' +
    cfg['owner'].name +
    '-' +
    cfg['site'].name +
    '-' +
    cfg['operation'].name +
    '.docx';

  docx.Packer.toBlob(doc).then((blob) => {
    console.log('Filename', filename);
    saveAs(blob, filename);
    console.log('Document created successfully');
  });
}

export default generateDocxReport;
