import { Canvas } from '@react-three/fiber';
import { observer, inject } from 'mobx-react';
import React, { Component, useEffect, useRef, useState } from 'react';
import { Suspense } from 'react';
import { Icon } from 'semantic-ui-react';

import packageInfo from '../../package.json';
import ChangeViewButtons from '../Components/ChangeViewButtons.js';
import InfoPanel from '../Components/InfoPanel.js';
import ObservationPanel from '../Components/ObservationPanel.js';
import { Operate } from '../Components/Operate';
import PoiList from '../Components/Poi.js';
import { Screenshot } from '../Components/Report/Screenshot';
import RovPos from '../Components/RovPos.js';
import Status from '../Components/Status.js';
import logo_wl from '../assets/images/waterlinked_logo.png';
import userLogo from '../lib/userlogo';
import { CameraController } from '../threejs/Camera';
import { FishCage } from '../threejs/FishCage';
import { GuideLines } from '../threejs/GuideLines';
import { POIs } from '../threejs/Poi';
import { RefBoat } from '../threejs/RefBoat';
import { ROV } from '../threejs/Rov';
import { Trail } from '../threejs/Trail';

const ThreeGui = inject(
  'poiStore',
  'positionStore',
  'trackpointsStore',
  'cfg',
  'statusStore',
  'preferences'
)(
  observer((props) => {
    const canvasRef = useRef();
    const { poiStore, positionStore, trackpointsStore, cfg, statusStore, preferences } = props;
    const { addPersistent } = trackpointsStore;
    const { mode, operation, cage_angle } = cfg;
    const [view, setView] = useState();
    const [imNumber, setImNumber] = useState();
    const [shoot, setShoot] = useState(false);
    const [guidelines, setGuidelines] = useState(false);
    const [recording, setRecording] = useState(true);
    const [logoHash, setLogoHash] = useState(Math.random());
    const [poiHover, setPoiHover] = useState();
    const [chunks, setChunks] = useState([]);

    const addTracking = () => {
      if (
        recording &&
        positionStore.position_valid &&
        (positionStore.isWashing || preferences.operation_mode === 'inspect')
      ) {
        const { x, y, z } = positionStore;
        const rz = Math.atan2(y - cage?.r_top, x) - Math.PI / 2;
        const rx = -Math.cos(rz) * cage_angle(z);
        const ry = -Math.sin(rz) * cage_angle(z);
        addPersistent(operation.id, x, y, -z, rx, ry, rz);
      }
    };

    useEffect(() => {
      poiStore.fetch();
      const id =
        mode === 'Active'
          ? setInterval(() => {
              addTracking();
            }, 1000)
          : null;
      return () => {
        clearInterval(id);
      };
    }, [mode, positionStore.isWashing, positionStore.position_valid, recording]);

    const onGenerateReport = async () => {
      setShoot(true);
    };

    const cage = cfg.cage?.id
      ? cfg.cage
      : {
          r_top: 25,
          depth: 35,
          felling_height: 15,
          demo: true,
        };

    return (
      <div style={{ height: '100vh' }}>
        <InterfaceBrancher
          onGenerateReport={onGenerateReport}
          setGuidelines={setGuidelines}
          guidelines={guidelines}
          setView={setView}
          view={view}
          recording={recording}
          setRecording={setRecording}
          logoHash={logoHash}
          setLogoHash={setLogoHash}
          {...preferences}
          setPoiHover={setPoiHover}
        />
        <Operate
          preferences={preferences}
          cfg={cfg}
          setShoot={setShoot}
          setRecording={setRecording}
          logoHash={logoHash}
          setLogoHash={setLogoHash}
        />

        <Canvas
          performance={{ min: 0.5 }}
          ref={canvasRef}
          pixelRatio={window.devicePixelRatio}
          gl={{ autoClear: false, alpha: true }}
          style={{
            opacity: mode === 'Idle' && !operation?.id ? 0.2 : 1,
            height: '100vh',
            position: 'absolute',
            top: 0,
          }}
          camera={{ position: [0, -4 * cage.r_top, cage.depth], fov: 30, near: 1, far: 1000, up: [0, 0, 1] }}
        >
          <CameraController cage={cage} view={view} im_number={imNumber} />
          <ambientLight />
          <pointLight position={[10, 10, 10]} />

          <Suspense fallback={null}>
            <FishCage cage={cage} />
            <ROV {...positionStore} {...trackpointsStore} {...cfg} {...statusStore} {...preferences} />
            <Trail trackpoints={trackpointsStore} cfg={cfg} {...preferences} chunks={chunks} setChunks={setChunks} />
            {/* <Chunks {...preferences} chunks={chunks} /> */}
            <POIs pois={poiStore} {...cfg} poiHover={poiHover} />
            <RefBoat cfg={cfg} />
            <GuideLines {...cfg} guidelines={guidelines} />
          </Suspense>
          <Screenshot
            shoot={shoot}
            setShoot={setShoot}
            cfg={cfg}
            poiStore={poiStore}
            setImNumber={setImNumber}
            setView={setView}
            preferences={preferences}
          />
        </Canvas>
      </div>
    );
  })
);

// const EndOperationButton = inject('cfg')(
//   observer(
//     class EndOperationButton extends Component {
//       state = { loading: false, isOpen: false };
//       handleOpen = () => {
//         this.setState({ isOpen: true });
//       };
//       handlePopClose = () => {
//         this.setState({ isOpen: false });
//       };
//       handleClose = () => {
//         this.setState({ loading: true, isOpen: false });
//         this.props.cfg.endOperation();
//         this.props.onClick();
//       };
//       componentWillUnmount() {
//         this.setState({ loading: false, isOpen: false });
//       }

//       render() {
//         return (
//           <Popup
//             trigger={
//               <Button color="grey" content={this.props.content} icon="arrow left" loading={this.state.loading} />
//             }
//             open={this.state.isOpen}
//             onOpen={this.handleOpen}
//             onClose={this.handlePopClose}
//             content={
//               <Button color="red" onClick={this.handleClose}>
//                 Confirm
//               </Button>
//             }
//             on="click"
//           />
//         );
//       }
//     }
//   )
// );

// class EndViewButton extends Component {
//   state = { loading: false };

//   handleClose = () => {
//     this.setState({ loading: true });
//     this.props.onGenerateReport();
//   };
//   componentWillUnmount() {
//     this.setState({ loading: false });
//   }

//   render() {
//     return (
//       <Popup
//         data-html2canvas-ignore
//         trigger={
//           <Button
//             color="blue"
//             content="Generate report"
//             labelPosition="right"
//             icon="sign in alternate"
//             loading={this.state.loading}
//           />
//         }
//         content={
//           <Button data-html2canvas-ignore color="blue" onClick={this.handleClose}>
//             Confirm and return to setup
//           </Button>
//         }
//         on="click"
//       />
//     );
//   }
// }

// const Operations = inject('cfg')(
//   observer(
//     class Setup extends Component {
//       returnToIdle = () => {
//         switch (this.props.cfg.mode) {
//           case 'Active':
//             this.props.onGenerateReport();
//             break;
//           case 'View':
//           default:
//             this.props.cfg.changeModeTo('Idle');
//         }
//       };
//       render() {
//         switch (this.props.cfg.mode) {
//           case 'Active':
//             return (
//               <div style={{ position: 'absolute', bottom: '1em', left: '45%' }}>
//                 <EndOperationButton content="End operation" onClick={this.returnToIdle} />
//               </div>
//             );
//           case 'View':
//             return (
//               <div data-html2canvas-ignore style={{ position: 'absolute', bottom: '1em', left: '38%' }}>
//                 <EndViewButton content="Return to setup" onGenerateReport={this.props.onGenerateReport} />
//                 <EndOperationButton content="Return to setup" onClick={this.returnToIdle} />
//               </div>
//             );
//           default:
//             return <div>Error, invalid mode {this.props.cfg.mode}</div>;
//         }
//       }
//     }
//   )
// );

// InterfaceBrancher selects the interface which suits the current mode
const InterfaceBrancher = inject(
  'positionStore',
  'cfg',
  'statusStore'
)(
  observer(
    class Interface extends Component {
      getObservation = (observationData) => {
        const { bioFauling, penShape, deadFish } = observationData;
        this.props.cfg['bioFauling'] = bioFauling;
        this.props.cfg['penShape'] = penShape;
        this.props.cfg['deadFish'] = deadFish;
      };

      render() {
        const { cfg, setPoiHover } = this.props;
        return (
          <div>
            <div id="interface">
              <HeaderAndFooter {...this.props} />
              <ChangeViewButtons {...this.props} {...cfg} />
              <Status {...this.props.statusStore} {...this.props.positionStore} {...this.props} />
              {cfg.mode !== 'Idle' && (
                <>
                  {cfg.mode === 'Active' && <RovPos {...this.props.positionStore} {...this.props} />}
                  <PoiList setPoiHover={setPoiHover} />
                  <InfoPanel owner={cfg.owner?.name} site={cfg.site?.name} cage={cfg.cage?.name} />
                  <ObservationPanel {...cfg} />
                </>
              )}
            </div>
          </div>
        );
      }
    }
  )
);

const HeaderAndFooter = ({ logoHash, operation_mode }) => (
  <>
    <header>
      <div id="title-area">
        <h1>CageSense {operation_mode && (operation_mode === 'clean' ? 'NetClean GPS' : 'NetInspect GPS')}</h1>
        <img alt="Water Linked logo" src={logo_wl} width="171px" style={{ paddingTop: '0.0em' }} />
      </div>
    </header>
    <div id="version-area">
      <a href="http://172.16.1.94/#/diagnostic" target="_new">
        <Icon name="linkify" /> Diagnostic view{' '}
      </a>
      <br />
      Version {packageInfo.version}
    </div>
    <div className="logo-area">
      <img
        src={userLogo + '?' + logoHash}
        alt="Logo"
        onError={(e) => {
          e.target.onError = null;
          e.target.style.visibility = 'hidden';
        }}
      />
    </div>
  </>
);

export default ThreeGui;
