import * as html2canvas from 'html2canvas';
import { observer, inject } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import generateDocxReport from '../Components/Report/GenerateReportDOCX';
import logo_wl_dark from '../assets/images/waterlinked_logo.png';
import { getImage } from '../lib/image';
import userLogo from '../lib/userlogo';

async function screenshot() {
  return html2canvas(document.body).then(function (canvas) {
    var screenShot = {
      data: canvas.toDataURL('image/jpeg', 0.7),
      width: canvas.width,
      height: canvas.height,
    };
    return screenShot;
  });
}

async function gen(cfg, poiStore) {
  const scr1 = await screenshot();
  const scr2 = await screenshot();

  //data["screenshots"] = [scr1, scr2]

  const logo1 = await getImage(userLogo);
  const logo2 = await getImage(logo_wl_dark);

  const screenShots = {
    first: scr1,
    second: scr2,
    third: scr2,
    fourth: scr2,
    custom_logo: logo1,
    logo: logo2,
  };

  generateDocxReport({ cfg, poiStore, screenShots });
}

const DebugRoute = inject(
  'cfg',
  'poiStore'
)(
  observer(({ poiStore, cfg }) => {
    return (
      <div>
        <div className="choose-area">
          <h1>Debug features</h1>
          <div style={{ paddingTop: '2em' }}>
            <Button onClick={() => gen(cfg, poiStore)} content="Generate docx" />
          </div>

          <div style={{ paddingTop: '5em' }}>
            <h2>Current user logo</h2>
            <img alt="User logo if added, otherwise nothing" src={userLogo} />

            <p>
              Upload <Link to="/upload">new logo</Link>
            </p>
          </div>
        </div>
      </div>
    );
  })
);

export default DebugRoute;
