import { extendObservable, action, makeAutoObservable, runInAction } from 'mobx';

import { superFetch, superFetchHeaders } from '../lib/fetch';

class TrackpointsStore {
  constructor() {
    makeAutoObservable(this);
    extendObservable(this, {
      points: [],
      interpolated: [],
      addPoint: action((data) => {
        this.points.push(data);
      }),
      getHref: (id, opId) => {
        return '/api/v1/operation/' + (opId ? opId : this.op_id.toString()) + '/trackpoint/' + id;
      },
      addInterpolated: action((x, y, z, rx, ry, rz, width, height) => {
        this.interpolated.push({ x: x, y: y, z: z, rx: rx, ry: ry, rz: rz, width: width, height: height });
      }),
      addPersistent: action((opId, x, y, z, rx, ry, rz, name = null) => {
        const _name = name || 'Type'; //"Trackpoint " + (this.count+1)
        let data = {
          name: _name,
          x: x,
          y: y,
          z: z,
          rx: rx,
          ry: ry,
          rz: rz,
          ts: '2018-07-17T10:27:29.699536549+02:00',
        };
        return superFetchHeaders(this.getHref('', opId), 'POST', data)
          .then((response) => {
            if (response.status === 201) {
              return superFetchHeaders(response.headers.get('Location'));
            } else if (response.status === 403) {
              throw Error(
                'Unable to add Trackpoint. You have exceeded maximum number of Trackpoints.' +
                  'Please delete a point before adding a new.'
              );
            } else {
              throw Error('Unable to add Trackpoint: ' + response.statusText);
            }
          })
          .then((response) => {
            if (response.status !== 200) {
              throw Error('Unable to fetch newly added Trackpoint: ' + response.statusText);
            }
            return response;
          })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.addPoint(data);
          })
          .catch((err) => {
            this.setErrorAddMsg(err.toString());
          });
      }),
      fetch: action(() => {
        if (!this.op_id || this.op_id === '') {
          runInAction(() => {
            this.points = [];
            this.interpolated = [];
          });

          return;
        }
        const url = this.getHref('');
        return superFetch(url)
          .then((data) => {
            runInAction(() => {
              this.points = data.map((d) => d);
            });
          })
          .catch((err) => {
            throw Error(err.toString());
          });
      }),
    });
  }
}

export { TrackpointsStore };
