import { useThree } from '@react-three/fiber';
import * as html2canvas from 'html2canvas';
import { useEffect } from 'react';

import logo_wl_dark from '../../assets/images/waterlinked_logo_dark.png';
import { getImage } from '../../lib/image';
import userLogo from '../../lib/userlogo';
import generateDOCXReport from './GenerateReportDOCX';
import generatePDFReport from './GenerateReportPDF';

// import logo from '../assets/images/waterlinked_logo_dark.png';

const Screenshot = ({ ...props }) => {
  const { shoot, setShoot, cfg, poiStore, setImNumber, setView, preferences } = props;
  const { gl, scene, camera } = useThree();

  useEffect(async () => {
    if (shoot) {
      await screenShots();
      setShoot(false);
    }
  }, [shoot]);

  const screenShots = async () => {
    setView('screenShot');
    try {
      await getScreenShot(0);
      const first = await getScreenShot(0);
      await getScreenShot(1);
      const second = await getScreenShot(1);
      await getScreenShot(2);
      const third = await getScreenShot(2);
      await getScreenShot(3);
      const fourth = await getScreenShot(3);
      await getScreenShot(4);
      const fifth = await getScreenShot(4);
      const custom_logo = await getImage(userLogo + '?' + Math.random());
      const wl_logo = await getImage(logo_wl_dark);
      const screenShots = {
        first: first,
        second: second,
        third: third,
        fourth: fourth,
        fifth: fifth,
        custom_logo: custom_logo,
        logo: wl_logo,
      };
      if (shoot === 'pdf') await generatePDFReport({ cfg, poiStore, screenShots, preferences });
      else await generateDOCXReport({ cfg, poiStore, screenShots, preferences });
      cfg.changeModeTo('Idle');
    } catch (err) {
      console.log('Report generation error', err);
      cfg.changeModeTo('Idle');
    }
  };

  const getScreenShot = async (im_number) => {
    setImNumber(im_number);
    gl.render(scene, camera);
    const canvas = await html2canvas(document.body);
    return {
      data: canvas.toDataURL('image/jpeg', 0.7),
      width: canvas.width,
      height: canvas.height,
    };
  };
  return null;
};

export { Screenshot };
