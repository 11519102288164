import { useFrame } from '@react-three/fiber';
import { Observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { BoxBufferGeometry, MeshStandardMaterial } from 'three';

const dummy = new THREE.Object3D();

const Trail = (props) => {
  const ref = useRef();
  const { trackpoints, cfg, wash_tile_type, wash_tile_height, wash_tile_thickness, wash_tile_width } = props;
  const [curve, setCurve] = React.useState();

  const geometry = useMemo(
    () => new BoxBufferGeometry(wash_tile_width, wash_tile_thickness, wash_tile_height),
    [new BoxBufferGeometry(wash_tile_width, wash_tile_thickness, wash_tile_height)]
  );
  const material = useMemo(() => new MeshStandardMaterial({ color: '#4bef8f', metalness: 0.6 }), []);

  useEffect(() => {
    trackpoints.op_id = cfg.operation?.id || '';
    trackpoints.fetch();
  }, [cfg?.operation?.id]);

  useEffect(() => {
    if (wash_tile_type === 'traceline') {
      setCurve(
        new THREE.CatmullRomCurve3(trackpoints.points.map((point) => new THREE.Vector3(point.x, point.y, -point.z)))
      );
    }
  }, [trackpoints?.points?.length, wash_tile_type]);

  useFrame(() => {
    if (wash_tile_type === 'tiles' && ref.current?.instanceMatrix) {
      let i = 0;
      trackpoints.points.map((point) => {
        const { x, y, z, rx, ry, rz } = point;
        dummy.position.set(x, y, -z);
        dummy.rotation.set(rx, ry, rz);
        dummy.updateMatrix();
        ref.current.setMatrixAt(i++, dummy.matrix);
      });

      ref.current.instanceMatrix.needsUpdate = true;
    }
  });

  return (
    <>
      <Observer>
        {() => {
          if (wash_tile_type === 'traceline' && curve?.points?.length > 1)
            return (
              <mesh>
                <tubeGeometry args={[curve, 10000, 0.1, 10, false]} />
                <meshStandardMaterial color={'#4bef8f'} metalness={0.7} />
              </mesh>
            );
          else if (wash_tile_type === 'tiles' && trackpoints?.points?.length)
            return <instancedMesh ref={ref} args={[geometry, material, trackpoints.points.length]} />;
          else return null;
        }}
      </Observer>
    </>
  );
};

export { Trail };
