import { extendObservable, makeAutoObservable, action } from 'mobx';

class Heading {
  constructor() {
    makeAutoObservable(this);
    extendObservable(this, {
      heading: 0,
      temp_starboard: true,
      setTempStarboard: action((isStartboard) => {
        this.temp_starboard = isStartboard;
      }),
    });
  }
}
export default Heading;
