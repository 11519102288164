import { useGLTF } from '@react-three/drei';
import React from 'react';

const RefBoat = ({ cfg }) => {
  const { nodes } = useGLTF('models/referenceboat.gltf');

  return cfg?.operation?.is_starboard !== undefined ? (
    <group
      dispose={null}
      scale={0.4}
      position={[0, -1.5, -0.2]}
      rotation={[0, 0, cfg.operation?.is_starboard ? 0 : Math.PI]}
    >
      <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
    </group>
  ) : null;
};

export { RefBoat };
