import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

// import { LegacyJSONLoader } from 'three/examples/jsm/loaders/deprecated/LegacyJSONLoader.js';

//const boat_width = 1.3;

const FishCage = (props) => {
  const ref = useRef();
  const { r_top, depth, felling_height, demo } = props.cage;
  const { nodes } = useGLTF('models/Cage_Polarcirkel.glb');

  const x = 0;

  useEffect(() => {
    if (!demo) {
      ref.current.rotation.x = 0;
      ref.current.rotation.y = 0;
      ref.current.rotation.z = 0;
      ref.current.position.y = 0;
    }
  }, [demo]);

  useFrame((state) => {
    if (demo) {
      const t = state.clock.getElapsedTime();
      ref.current.rotation.x = Math.PI - Math.PI + Math.cos(t / 4) / 8;
      ref.current.rotation.y = Math.sin(t / 4) / 8;
      ref.current.rotation.z = (1 + Math.sin(t / 1.5)) / 20;
      ref.current.position.y = (1 + Math.sin(t / 1.5)) / 10;
    }
  });

  return (
    <group ref={ref} dispose={null}>
      {props.cage && (
        <>
          <mesh
            geometry={nodes.Walkway.geometry}
            material={nodes.Walkway.material}
            position={[x, r_top, 11 / (27.5 / r_top) / 5]}
            scale={0.015 / (27.5 / r_top)}
            // position={[0, 135, 0]}
            rotation={[Math.PI, 0, 0]}
            receiveShadow
          >
            <meshStandardMaterial color={'black'} />
          </mesh>

          <mesh
            position={[x, r_top, -felling_height / 2]}
            rotation={[(90 * Math.PI) / 180, 0, 0]}
            name={'fish_cage_side'}
          >
            <cylinderGeometry args={[r_top, r_top, felling_height, 64, 1, false]} />
            <meshLambertMaterial
              wireframe={false}
              opacity={0.2}
              transparent
              color={'#bfe7e7'}
              side={THREE.BackSide}
              shadowSide={THREE.FrontSide}
            />
          </mesh>

          <mesh
            position={[x, r_top, -(depth / 2) - felling_height / 2 - 0.1]}
            rotation={[(90 * Math.PI) / 180, 0, 0]}
            name={'fish_cage_bottom'}
          >
            <cylinderGeometry args={[r_top, 0, depth - felling_height, 32, 1, false]} />
            <meshLambertMaterial
              wireframe={false}
              opacity={0.2}
              transparent
              color={'#bfe7e7'}
              side={THREE.DoubleSide}
            />
          </mesh>
        </>
      )}
    </group>
  );
};

// useGLTF.preload('models/Cage_Polarcirkel.glb')

export { FishCage };
/*
export default (scene, cfg) => {
  init();
  function init() {
    // Top
    var objectLoader = new THREE.ObjectLoader();

    objectLoader.load('models/topside.json', function (object) {
      object.rotation.x = -Math.PI / 2;

      var scaleValue = 11 / (27.5 / r_top);
      object.scale.set(scaleValue, scaleValue, scaleValue);
      object.position.set(0, offset, 0);
      object.name = 'fish_cage_top';
      scene.add(object);
    });

    // Side
    const side_geometry = new THREE.CylinderGeometry(r_top, r_top, felling_height, 64, 1, false);
    const side_material = new THREE.MeshLambertMaterial({
      wireframe: false,
      opacity: 0.2,
      transparent: true,
      color: 0xbfe7e7,
    });
    const side = new THREE.Mesh(side_geometry, side_material);
    side.material.side = THREE.BackSide;
    side.material.shadowSide = THREE.FrontSide;

    side.position.set(0, offset, -felling_height / 2);
    side.rotation.x = (90 * Math.PI) / 180;
    side.name = 'fish_cage_side';
    scene.add(side);

    // Bottom
    const bottom_geometry = new THREE.CylinderGeometry(r_top, 0, depth - felling_height, 32, 1, false);
    const bottom_material = new THREE.MeshLambertMaterial({
      wireframe: false,
      opacity: 0.2,
      transparent: true,
      color: 0xbfe7e7,
    });
    const bottom = new THREE.Mesh(bottom_geometry, bottom_material);
    bottom.material.side = THREE.DoubleSide;
    //bottom.material.shadowSide = THREE.FrontSide;

    bottom.position.set(0, offset, -(depth / 2) - felling_height / 2);
    bottom.rotation.x = (90 * Math.PI) / 180;
    bottom.name = 'fish_cage_bottom';
    scene.add(bottom);
  }

  function loaded() {
    if (scene.getObjectByName('fish_cage_top')) {
      return true;
    } else {
      return false;
    }
  }

  function update() {} // Nothing needs to be updated

  function initUpdate() {
    scene.remove(scene.getObjectByName('fish_cage_side'));
    scene.remove(scene.getObjectByName('fish_cage_bottom'));

    if (cfg.cage !== undefined) {
      r_top = cfg.cage.r_top;
      felling_height = cfg.cage.felling_height;
      depth = cfg.cage.depth;
      offset = r_top + boat_width;
    }

    // Top
    let scaleValue = 11 / (27.5 / r_top);
    var top = scene.getObjectByName('fish_cage_top');
    if (top !== undefined) {
      top.scale.set(scaleValue, scaleValue, scaleValue);
      top.position.set(0, offset, 0);
    }

    // Side
    const side_geometry = new THREE.CylinderGeometry(r_top, r_top, felling_height, 64, 1, false);
    const side_material = new THREE.MeshLambertMaterial({
      wireframe: false,
      opacity: 0.2,
      transparent: true,
      color: 0xbfe7e7,
    });
    const side = new THREE.Mesh(side_geometry, side_material);
    side.material.side = THREE.BackSide;
    side.material.shadowSide = THREE.FrontSide;

    side.position.set(0, offset, -felling_height / 2);
    side.rotation.x = (90 * Math.PI) / 180;
    side.name = 'fish_cage_side';
    scene.add(side);

    // Bottom
    const bottom_geometry = new THREE.CylinderGeometry(r_top, 0, depth - felling_height, 32, 1, false);
    const bottom_material = new THREE.MeshLambertMaterial({
      wireframe: false,
      opacity: 0.2,
      transparent: true,
      color: 0xbfe7e7,
    });
    const bottom = new THREE.Mesh(bottom_geometry, bottom_material);
    bottom.material.side = THREE.BackSide;
    bottom.material.shadowSide = THREE.FrontSide;

    bottom.position.set(0, offset, -(depth / 2) - felling_height / 2);
    bottom.rotation.x = (90 * Math.PI) / 180;
    bottom.name = 'fish_cage_bottom';
    scene.add(bottom);
  }

  return {
    update,
    loaded,
    initUpdate,
  };
};
*/
