import React, { Component } from 'react';
import { Dropdown, Form, TextArea } from 'semantic-ui-react';

import './ObservationArea.css';
import { THeadSpan } from './Table.js';

const bioFaulingList = [
  {
    key: 'Small',
    text: 'Small',
    value: 'Small',
  },
  {
    key: 'Medium',
    text: 'Medium',
    value: 'Medium',
  },
  {
    key: 'Large',
    text: 'Large',
    value: 'Large',
  },
];

const penShapeList = [
  {
    key: 'Loose',
    text: 'Loose',
    value: 'Loose',
  },
  {
    key: 'Tight',
    text: 'Tight',
    value: 'Tight',
  },
];

class ObservationPanel extends Component {
  render() {
    const { bioFauling, penShape, deadFish, setBioFauling, setPenShape, setDeadFish, mode } = this.props;
    return (
      <table className="readout-area" style={{ bottom: '30em', right: '1em' }}>
        <THeadSpan>Cage condition</THeadSpan>
        <tbody>
          <tr className="poiTable" style={{ height: '40px' }}>
            <td>Bio Fauling</td>
            <td style={{ textAlign: 'right' }}>
              <Dropdown
                disabled={mode === 'Active' ? false : true}
                basic
                inline
                clearable
                text={bioFauling}
                placeholder="-"
                options={bioFaulingList}
                onChange={(e, { value }) => {
                  setBioFauling(value);
                }}
              />
            </td>
          </tr>
          <tr className="poiTable" style={{ height: '40px' }}>
            <td>Pen Shape</td>
            <td style={{ textAlign: 'right' }}>
              <Dropdown
                disabled={mode === 'Active' ? false : true}
                basic
                inline
                clearable
                placeholder="-"
                text={penShape}
                options={penShapeList}
                onChange={(e, { value }) => {
                  setPenShape(value);
                }}
              />
            </td>
          </tr>
          <tr className="poiTable" style={{ height: '40px' }}>
            <td colSpan={2}>
              <Form>
                <TextArea
                  disabled={mode === 'Active' ? false : true}
                  style={{ maxHeight: '200px' }}
                  rows={2}
                  placeholder="Deadfish Liftup/ Mort collector"
                  value={deadFish}
                  onChange={(e, { value }) => setDeadFish(value)}
                />
              </Form>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default ObservationPanel;
