import { Provider } from 'mobx-react';
import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import './Components/Table.css';
import Debug from './Routes/Debug';
import ThreeGui from './Routes/ThreeGui';
import Config from './Stores/Config';
import Heading from './Stores/Heading';
import PointsOfInterestStore from './Stores/Poi';
import { FNCPositionStore } from './Stores/Position';
import { PreferencesStore } from './Stores/Preferences';
import { StatusStore } from './Stores/Status';
import { TrackpointsStore } from './Stores/Trackpoints';
import MessageStore from './Stores/Warnings';

const poiStore = new PointsOfInterestStore();
const cfg = new Config();
const heading = new Heading();
const trackpointsStore = new TrackpointsStore();
const statusStore = new StatusStore();
const preferences = new PreferencesStore();
const warningsStore = new MessageStore();
const fps = new FNCPositionStore();

// cfg.updateOptionsOfType('owner');
// cfg.updateOperationOptions();
preferences.getPreferences();
preferences.getRov();

setInterval(() => {
  warningsStore.fetch();
}, 1500);
setInterval(() => {
  if (cfg.mode !== 'Idle') fps.fetch();
}, 200);

const OperatingMode = () => {
  //To change operating mode, navigate to /inspect or /clean
  const location = useLocation();
  if (location.pathname.includes('inspect')) {
    preferences.modifyRov({
      operation_mode: 'inspect',
      rov_model: 'other',
      wash_tile_width: 1,
      wash_tile_height: 1,
      wash_tile_thickness: 0.1,
      wash_tile_type: 'tiles',
    });
  } else if (location.pathname.includes('clean')) {
    preferences.modifyRov({
      operation_mode: 'clean',
      rov_model: 'fnc8',
      wash_tile_width: 1.5,
      wash_tile_height: 4,
      wash_tile_thickness: 0.2,
      wash_tile_type: 'tiles',
    });
  }

  return <Navigate to="/" replace />;
};

const App = () => {
  return (
    <>
      <Provider
        positionStore={fps}
        poiStore={poiStore}
        trackpointsStore={trackpointsStore}
        cfg={cfg}
        statusStore={statusStore}
        heading={heading}
        warningsStore={warningsStore}
        preferences={preferences}
      >
        <Routes>
          <Route path="/debug" element={<Debug />} />
          <Route path="/" element={<ThreeGui />} />
          <Route path="*" element={<OperatingMode />} />
        </Routes>
      </Provider>
    </>
  );
};

export default App;
