import * as docx from 'docx';
import moment from 'moment';

import * as presets from './docxPresets';

const createParagraph = (content) => {
  return new docx.Paragraph({
    text: content,
    spacing: presets.spacingMore,
  });
};

const createHeading = (text) => {
  return new docx.Paragraph({
    text: text,
    heading: docx.HeadingLevel.HEADING_1,
    thematicBreak: true,
  });
};

const createSubHeading = (text) => {
  return new docx.Paragraph({
    text: text,
    heading: docx.HeadingLevel.HEADING_2,
  });
};

function createFooterTable() {
  const today = new moment().format('YYYY-MM-DD');

  return new docx.Table({
    rows: [
      new docx.TableRow({
        children: [
          // Todays date
          new docx.TableCell({
            children: [createParagraph(today)],
            width: presets.fullWidth,
            borders: presets.topBorderOnly,
          }),
          // Page
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.RIGHT,
                children: [
                  new docx.TextRun({
                    children: ['Page: ', docx.PageNumber.CURRENT],
                  }),
                  new docx.TextRun({
                    children: [' / ', docx.PageNumber.TOTAL_PAGES],
                  }),
                ],
              }),
            ],
            width: presets.fullWidth,
            borders: presets.topBorderOnly,
          }),
        ],
      }),
    ],
    width: presets.fullWidth,
    columnWidths: [docx.convertMillimetersToTwip(40), docx.convertMillimetersToTwip(120)],
  });
}

function createHeaderImages(image1, image2) {
  const imageHeight = 36;

  console.log(image1);
  console.log(image2);

  const img1 =
    image1 && image1.data
      ? [
          new docx.ImageRun({
            data: image1['data'],
            transformation: {
              width: imageHeight * image1['aspect'],
              height: imageHeight,
            },
          }),
        ]
      : [];

  const img2 =
    image2 && image2.data
      ? [
          new docx.ImageRun({
            data: image2['data'],
            transformation: {
              width: imageHeight * image2['aspect'],
              height: imageHeight,
            },
          }),
        ]
      : [];

  return new docx.Table({
    rows: [
      new docx.TableRow({
        children: [
          // Todays date
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                children: img1,
                spacing: presets.spacingMore,
              }),
            ],
            width: presets.fullWidth,
            borders: presets.bottomBorderOnly,
          }),
          // Page
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.RIGHT,
                children: img2,
                spacing: presets.spacingMore,
              }),
            ],
            width: presets.fullWidth,
            borders: presets.bottomBorderOnly,
          }),
        ],
      }),
    ],
    width: presets.fullWidth,
    columnWidths: [docx.convertMillimetersToTwip(40), docx.convertMillimetersToTwip(120)],
  });
}

const createTableChildren = (header, rows) => {
  const tableRows = rows.map((items) => {
    return new docx.TableRow({
      children: [
        new docx.TableCell({
          children: [createParagraph(items[0])],
          width: {
            size: 100,
            type: docx.WidthType.AUTO,
          },
          borders: presets.noBorder,
          spacing: presets.spacingMore,
        }),
        new docx.TableCell({
          children: [items[1]],
          width: {
            size: 100,
            type: docx.WidthType.AUTO,
          },
          borders: presets.noBorder,
          spacing: presets.spacingMore,
        }),
      ],
    });
  });

  return new docx.Table({
    rows: [
      new docx.TableRow({
        children: [
          new docx.TableCell({
            children: [createParagraph(header)],
            columnSpan: 2,
            shading: presets.tableHeaderShading,
            width: {
              size: 100,
              type: docx.WidthType.AUTO,
            },
            borders: presets.noBorder,
            spacing: presets.spacingMore,
          }),
        ],
      }),
      ...tableRows,
    ],
    width: {
      size: 100,
      type: docx.WidthType.AUTO,
    },
    borders: presets.ousideBorder,
    columnWidths: [docx.convertMillimetersToTwip(40), docx.convertMillimetersToTwip(120)],
  });
};

const createTable2 = (header, rows) => {
  const tableRows = rows.map((items) => {
    return new docx.TableRow({
      children: [
        new docx.TableCell({
          children: [createParagraph(items[0])],
          width: {
            size: 100,
            type: docx.WidthType.AUTO,
          },
          borders: presets.noBorder,
          spacing: presets.spacingMore,
        }),
        new docx.TableCell({
          children: [
            new docx.Paragraph({
              spacing: presets.spacingMore,
              children: [
                new docx.TextRun({
                  text: items[1],
                  bold: true,
                }),
              ],
            }),
          ],
          width: {
            size: 100,
            type: docx.WidthType.AUTO,
          },
          borders: presets.noBorder,
        }),
      ],
    });
  });

  return new docx.Table({
    rows: [
      new docx.TableRow({
        children: [
          new docx.TableCell({
            children: [createParagraph(header)],
            columnSpan: 2,
            shading: presets.tableHeaderShading,
            width: {
              size: 100,
              type: docx.WidthType.AUTO,
            },
            borders: presets.noBorder,
            spacing: presets.spacingMore,
          }),
        ],
      }),
      ...tableRows,
    ],
    width: {
      size: 100,
      type: docx.WidthType.AUTO,
    },
    borders: presets.ousideBorder,
    columnWidths: [docx.convertMillimetersToTwip(40), docx.convertMillimetersToTwip(120)],
  });
};

const createImage = (img) => {
  return new docx.Paragraph({
    children: [
      new docx.ImageRun({
        data: img.data,
        transformation: {
          width: img.width / 3,
          height: img.height / 3,
        },
      }),
    ],
    spacing: {
      after: docx.convertMillimetersToTwip(2.0),
      before: docx.convertMillimetersToTwip(2.0),
    },
  });
};

export {
  createParagraph,
  createFooterTable,
  createHeading,
  createImage,
  createSubHeading,
  createTable2,
  createTableChildren,
  createHeaderImages,
};
