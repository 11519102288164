import { extendObservable, makeAutoObservable, action } from 'mobx';

class StatusStore {
  constructor() {
    makeAutoObservable(this);
    extendObservable(this, {
      currentSpeed: '-',
      avgSpeed: '-',
      duration: '-',
      estimatedCompletion: '-',
      signalStrength: '-',
      updateStatus: action((_status) => {
        this.currentSpeed = Math.round(_status[0]);
        this.avgSpeed = Math.round(_status[1]);
        this.duration = _status[2];
        this.estimatedCompletion = _status[3];
        this.signalStrength = _status[4];
      }),
    });
  }
  setDefault(_status) {
    this.currentSpeed = _status[0];
    this.avgSpeed = _status[1];
    this.duration = _status[2];
    this.estimatedCompletion = _status[3];
    this.signalStrength = _status[4];
  }
}

export { StatusStore };
