import { extendObservable, makeAutoObservable, action, runInAction } from 'mobx';

// import Config from './Config';

// const config = new Config();
// console.log(config.updateConfig);

class PreferencesStore {
  constructor() {
    makeAutoObservable(this);
    extendObservable(this, {
      owner_id: undefined,
      cage_id: undefined,
      site_id: undefined,
      docking_side: undefined,
      operation_mode: '',
      rov_model: 'fnc8',
      wash_tile_height: 4,
      wash_tile_thickness: 0.2,
      wash_tile_type: 'tile',
      wash_tile_width: 1.5,

      getPreferences: action(() => {
        try {
          return fetch('/api/v1/preferences/generic', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then((res) => (res.json ? res.json() : null))
            .then((data) => {
              runInAction(() => {
                Object.assign(this, data);
              });
            })
            .catch();
        } catch (error) {
          return error;
        }
      }),
      getRov: action(() => {
        return fetch('/api/v1/preferences/rov', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((res) => res.json())
          .then((data) => {
            runInAction(() => {
              Object.assign(this, data);
            });
          });
      }),

      postPreferences: action((data) => {
        return fetch('/api/v1/preferences/generic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((data) => {
            runInAction(() => {
              Object.assign(this, data);
            });
          });
      }),
      modifyRov: action((data) => {
        return fetch('/api/v1/preferences/rov', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((data) => {
            runInAction(() => {
              Object.assign(this, data);
            });
          });
      }),
    });
  }
}

export { PreferencesStore };
