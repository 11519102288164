// import * as jsPDF from 'jspdf';
import { jsPDF } from 'jspdf';
import moment from 'moment';

//import logo_ag from "../assets/images/AG_logo.png";
import logo_wl_dark from '../../assets/images/waterlinked_logo_dark.png';

/*
const checkPoi = ({poi,cfg}) => {
    console.log('checking poi')
    if(cfg.cage.id === poi.cage_id){
        if(cfg.operation.id === poi.operation_id){
            if(poi.hidden===false){
                console.log('Get new poi')
            }
        }
    }
}*/

const getImageAspect = (data) => {
  return new Promise(function (resolved, rejected) {
    var i = new Image();
    i.onload = function () {
      resolved(i.height / i.width);
    };
    i.onerror = function () {
      rejected();
    };
    i.src = 'data:image/png; base64,' + data.toString('base64');
  });
};

const addTopAndBottomSection = async (doc, general_info, custom_logo) => {
  const logo_align_y = 23;
  const logo_height = 10;
  const wLogoAspect = 170 / 800;
  const wLogoHeight = logo_height - 2;
  const wLogoWidth = wLogoHeight / wLogoAspect;
  doc.addImage(
    logo_wl_dark,
    'png',
    general_info.right - wLogoWidth,
    logo_align_y - wLogoHeight / 2,
    wLogoWidth,
    wLogoHeight
  );

  if (custom_logo && custom_logo.data) {
    const center = (general_info.left_most + general_info.right) / 2;
    const aspect = await getImageAspect(custom_logo.data);
    const logo_width = logo_height / aspect;
    doc.addImage(
      custom_logo.data,
      'png',
      center - logo_width / 2,
      logo_align_y - logo_height / 2,
      logo_width,
      logo_height
    );
  }

  // Add bottom section
  doc.setLineWidth(general_info.line_small);
  doc.setDrawColor(0, 0, 0);
  doc.line(
    general_info.left_most - general_info.line_small / 2,
    general_info.bottom_page,
    general_info.right + general_info.line_small / 2,
    general_info.bottom_page
  );
  var currentdate = new moment();
  var datetime = currentdate.format('YYYY-MM-DD');

  doc.setFont('helvetica', 'normal');
  doc.setFontSize(10);
  doc.text(general_info.left_most, general_info.bottom_page + 4, datetime);
  doc.text(general_info.right - 9, general_info.bottom_page + 4, 'Page ' + general_info.page_number);
};

const addNewPage = (doc, general_info, custom_logo) => {
  general_info.page_number += 1;
  doc.addPage();
  addTopAndBottomSection(doc, general_info, custom_logo);
};

const addPoiLines = ({ poi_top, i, doc, general_info }) => {
  var poi_bottom = poi_top + i * general_info.line_spacing;
  // POI area
  doc.setLineWidth(general_info.line_large);
  doc.setDrawColor(200, 200, 200);
  doc.line(
    general_info.left_most - general_info.margin - general_info.line_small / 2,
    poi_top - general_info.line_large / 6,
    general_info.right + general_info.margin + general_info.line_small / 2,
    poi_top - general_info.line_large / 6
  ); // horisontal

  doc.setLineWidth(general_info.line_small);
  doc.line(
    general_info.left_most - general_info.margin,
    poi_top,
    general_info.left_most - general_info.margin,
    poi_bottom
  );
  doc.line(general_info.right + general_info.margin, poi_top, general_info.right + general_info.margin, poi_bottom);
  doc.line(
    general_info.left_most - general_info.margin - general_info.line_small / 2,
    poi_bottom,
    general_info.right + general_info.margin + general_info.line_small / 2,
    poi_bottom
  );

  //Poi section
  doc.setFont('helvetica', 'bold');
  doc.text(general_info.left_most, poi_top, 'Points of Interest:');
};

const HeadingForPoi = (x, y) => {
  return (360 + Math.round((Math.atan2(x, y) * 180) / Math.PI)) % 360;
};

const addPoi = ({ poi, poi_top, i, j, doc, general_info }) => {
  doc.setFont('helvetica', 'normal');
  doc.text(general_info.left_most, poi_top + i * general_info.line_spacing, 'POI' + j);
  doc.text(general_info.left_most, poi_top + (i + 0.5) * general_info.line_spacing, ' - Name');
  doc.text(general_info.left_most, poi_top + (i + 1) * general_info.line_spacing, ' - Position');
  //Adding the information about the poi
  doc.setFont('helvetica', 'bold');
  doc.text(general_info.left_inside, poi_top + (i + 0.5) * general_info.line_spacing, poi.name);

  const heading = HeadingForPoi(poi.x, poi.y);
  const depth = -poi.z.toFixed(2);
  doc.text(
    general_info.left_inside,
    poi_top + (i + 1) * general_info.line_spacing,
    `degrees = ${heading}°, depth = ${depth} m`
  );
};

const addPoiInfo = ({ poiStore, cfg, doc, general_info, screenShots }) => {
  return new Promise((resolve) => {
    poiStore.fetch().then(() => {
      var i = 1;
      var j = 1;

      var poi_top = 300;

      for (let poi of poiStore.allVisible) {
        if (poi.operation_id === cfg.operation.id) {
          if (poi_top + i * general_info.line_spacing > 240) {
            console.log('add poi 1', poi);
            addPoiLines({ poi_top, i, doc, general_info });
            addNewPage(doc, general_info, screenShots.custom_logo);
            poi_top = 50;
            i = 1;
          }
          addPoi({ poi, poi_top, i, j, doc, general_info });
          i += 2;
          j += 1;
        } else if ((poi.cage_id === cfg.cage.id) & (poi.hidden === false)) {
          if (poi_top + i * general_info.line_spacing > 240) {
            console.log('add poi 2', poi);
            addPoiLines({ poi_top, i, doc, general_info });
            addNewPage(doc, general_info, screenShots.custom_logo);
            poi_top = 50;
            i = 1;
          }
          addPoi({ poi, poi_top, i, j, doc, general_info });
          i += 2;
          j += 1;
        }
      }
      if (i > 1) {
        addPoiLines({ poi_top, i, doc, general_info });
      }
      resolve();
    });
  });
};

const addscreenShotLines = ({ image_top, image_height, l, doc, general_info }) => {
  var image_bottom = image_top + l * image_height + l * 10;
  // POI area
  doc.setLineWidth(general_info.line_large);
  doc.setDrawColor(200, 200, 200);
  doc.line(
    general_info.left_most - general_info.margin - general_info.line_small / 2,
    image_top - 10 - general_info.line_large / 6,
    general_info.right + general_info.margin + general_info.line_small / 2,
    image_top - 10 - general_info.line_large / 6
  ); // horisontal

  doc.setLineWidth(general_info.line_small);
  doc.line(
    general_info.left_most - general_info.margin,
    image_top - 10,
    general_info.left_most - general_info.margin,
    image_bottom
  );
  doc.line(
    general_info.right + general_info.margin,
    image_top - 10,
    general_info.right + general_info.margin,
    image_bottom
  );
  doc.line(
    general_info.left_most - general_info.margin - general_info.line_small / 2,
    image_bottom,
    general_info.right + general_info.margin + general_info.line_small / 2,
    image_bottom
  );

  //Poi section
  doc.setFont('helvetica', 'bold');
  doc.text(general_info.left_most, image_top - 10, 'Screenshots:');
};

const addscreenShots = ({ cfg, doc, general_info, screenShots }) => {
  addNewPage(doc, general_info, screenShots.custom_logo);
  var image_top = 60;
  var image_width = 120;
  var image_height = (screenShots.first.height / screenShots.first.width) * image_width;
  var image_left = 45;
  var l = 0;
  var image_spacing = 10;
  doc.addImage(
    screenShots.first.data,
    'jpeg',
    image_left,
    image_top + l * image_spacing + l * image_height,
    image_width,
    image_height
  );
  l += 1;
  if (image_top + image_spacing + l * image_height > 200) {
    addscreenShotLines({ image_top, image_height, l, doc, general_info });
    addNewPage(doc, general_info, screenShots.custom_logo);
    l = 0;
  }
  doc.addImage(
    screenShots.second.data,
    'jpeg',
    image_left,
    image_top + l * image_spacing + l * image_height,
    image_width,
    image_height
  );
  l += 1;
  if (image_top + image_spacing + l * image_height > 200) {
    addscreenShotLines({ image_top, image_height, l, doc, general_info });
    addNewPage(doc, general_info, screenShots.custom_logo);
    l = 0;
  }
  doc.addImage(
    screenShots.third.data,
    'jpeg',
    image_left,
    image_top + l * image_spacing + l * image_height,
    image_width,
    image_height
  );
  l += 1;
  if (image_top + image_spacing + l * image_height > 200) {
    addscreenShotLines({ image_top, image_height, l, doc, general_info });
    addNewPage(doc, general_info, screenShots.custom_logo);
    l = 0;
  }
  doc.addImage(
    screenShots.fourth.data,
    'jpeg',
    image_left,
    image_top + l * image_spacing + l * image_height,
    image_width,
    image_height
  );

  l += 1;
  if (image_top + image_spacing + l * image_height > 200) {
    addscreenShotLines({ image_top, image_height, l, doc, general_info });
    addNewPage(doc, general_info, screenShots.custom_logo);
    l = 0;
  }
  doc.addImage(
    screenShots.fifth.data,
    'jpeg',
    image_left,
    image_top + l * image_spacing + l * image_height,
    image_width,
    image_height
  );

  l += 1;
  addscreenShotLines({ image_top, image_height, l, doc, general_info });
  // Setting name of PDF
  doc.save(
    'WaterLinked-Wash-report-' + cfg['owner'].name + '-' + cfg['site'].name + '-' + cfg['operation'].name + '.pdf'
  );
};

const onGenerateReport = async ({ cfg, poiStore, screenShots }) => {
  //const canvas_copy = this.three.getCanvas();
  if (cfg['operation']) {
    var doc = new jsPDF();

    // Setting positions for the pdf
    var general_info = {
      line_spacing: 10,
      line_large: 8,
      line_small: 0.25,
      left_most: 25,
      left_inside: 60,
      right: 185,
      bottom_page: 280,
      page_number: 1,
      margin: 2,
    };

    var cage_top = 50;
    var cage_bottom = 90;
    var docking_top = 100;
    var docking_bottom = 130;
    var operation_top = 140;
    var operation_bottom = 180;
    var observation_top = 190;
    var observation_bottom = 240;

    addTopAndBottomSection(doc, general_info, screenShots.custom_logo);

    //Adding lines
    // Cage area
    doc.setFont('helvetica', 'bold');
    doc.setLineWidth(general_info.line_large);
    doc.setDrawColor(200, 200, 200);
    doc.line(
      general_info.left_most - general_info.margin - general_info.line_small / 2,
      cage_top - general_info.line_large / 6,
      general_info.right + general_info.margin + general_info.line_small / 2,
      cage_top - general_info.line_large / 6
    ); // horisontal

    doc.setLineWidth(general_info.line_small);
    doc.line(
      general_info.left_most - general_info.margin,
      cage_top,
      general_info.left_most - general_info.margin,
      cage_bottom
    );
    doc.line(general_info.right + general_info.margin, cage_top, general_info.right + general_info.margin, cage_bottom);
    doc.line(
      general_info.left_most - general_info.margin - general_info.line_small / 2,
      cage_bottom,
      general_info.right + general_info.margin + general_info.line_small / 2,
      cage_bottom
    );

    // Docking area
    doc.setLineWidth(general_info.line_large);
    doc.line(
      general_info.left_most - general_info.margin - general_info.line_small / 2,
      docking_top - general_info.line_large / 6,
      general_info.right + general_info.margin + general_info.line_small / 2,
      docking_top - general_info.line_large / 6
    ); // horisontal

    doc.setLineWidth(general_info.line_small);
    doc.line(
      general_info.left_most - general_info.margin,
      docking_top,
      general_info.left_most - general_info.margin,
      docking_bottom
    );
    doc.line(
      general_info.right + general_info.margin,
      docking_top,
      general_info.right + general_info.margin,
      docking_bottom
    );
    doc.line(
      general_info.left_most - general_info.margin - general_info.line_small / 2,
      docking_bottom,
      general_info.right + general_info.margin + general_info.line_small / 2,
      docking_bottom
    );

    // Operation area
    doc.setLineWidth(general_info.line_large);
    doc.line(
      general_info.left_most - general_info.margin - general_info.line_small / 2,
      operation_top - general_info.line_large / 6,
      general_info.right + general_info.margin + general_info.line_small / 2,
      operation_top - general_info.line_large / 6
    ); // horisontal

    doc.setLineWidth(general_info.line_small);
    doc.line(
      general_info.left_most - general_info.margin,
      operation_top,
      general_info.left_most - general_info.margin,
      operation_bottom
    );
    doc.line(
      general_info.right + general_info.margin,
      operation_top,
      general_info.right + general_info.margin,
      operation_bottom
    );
    doc.line(
      general_info.left_most - general_info.margin - general_info.line_small / 2,
      operation_bottom,
      general_info.right + general_info.margin + general_info.line_small / 2,
      operation_bottom
    );

    // Observation area
    doc.setLineWidth(general_info.line_large);
    doc.line(
      general_info.left_most - general_info.margin - general_info.line_small / 2,
      observation_top - general_info.line_large / 6,
      general_info.right + general_info.margin + general_info.line_small / 2,
      observation_top - general_info.line_large / 6
    ); // horisontal

    doc.setLineWidth(general_info.line_small);
    doc.line(
      general_info.left_most - general_info.margin,
      observation_top,
      general_info.left_most - general_info.margin,
      observation_bottom
    );
    doc.line(
      general_info.right + general_info.margin,
      observation_top,
      general_info.right + general_info.margin,
      observation_bottom
    );
    doc.line(
      general_info.left_most - general_info.margin - general_info.line_small / 2,
      observation_bottom,
      general_info.right + general_info.margin + general_info.line_small / 2,
      observation_bottom
    );

    //Adding title
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(16);
    doc.text(general_info.left_most, 43, 'FNC8 Operation Report');
    doc.setFontSize(11);
    //doc.text(30,500,cfg['operation'].name)

    //Cage section
    doc.text(general_info.left_most, cage_top, 'Cage Info:');
    doc.setFont('helvetica', 'normal');
    doc.text(general_info.left_most, cage_top + general_info.line_spacing, 'Cage');
    doc.text(general_info.left_most, cage_top + 2 * general_info.line_spacing, 'Cage dimensions');

    //Adding the information about the cage
    doc.setFont('helvetica', 'bold');
    doc.text(
      general_info.left_inside,
      cage_top + general_info.line_spacing,
      cfg['owner'].name + ', ' + cfg['site'].name + ', ' + cfg['cage'].name
    );
    doc.text(
      general_info.left_inside,
      cage_top + 2 * general_info.line_spacing,
      'Top                              : ' + Math.round(cfg['cage'].r_top * 2 * Math.PI) + ' m'
    );
    doc.text(
      general_info.left_inside,
      cage_top + 2.5 * general_info.line_spacing,
      'Depth to angled pen  : ' + Math.round(cfg['cage'].felling_height) + ' m'
    );
    doc.text(
      general_info.left_inside,
      cage_top + 3 * general_info.line_spacing,
      'Total depth                 : ' + cfg['cage'].depth + ' m'
    );

    //Docking section
    doc.text(general_info.left_most, docking_top, 'Docking Info:');
    doc.setFont('helvetica', 'normal');
    doc.text(general_info.left_most, docking_top + general_info.line_spacing, 'Docking side');
    doc.text(general_info.left_most, docking_top + 2 * general_info.line_spacing, 'Heading');

    //Adding the information about the docking
    doc.setFont('helvetica', 'bold');
    const dockSideText = cfg['operation'].is_starboard === true ? 'Starboard' : 'Port';
    doc.text(general_info.left_inside, docking_top + general_info.line_spacing, dockSideText);
    doc.text(
      general_info.left_inside,
      docking_top + 2 * general_info.line_spacing,
      cfg['operation'].dock_angle + ' degrees'
    );

    //Operation section
    //var date_time = cfg['operation'].name.split("@");
    doc.text(general_info.left_most, operation_top, 'Operation:');
    doc.setFont('helvetica', 'normal');
    doc.text(general_info.left_most, operation_top + general_info.line_spacing, 'Start time');
    doc.text(general_info.left_most, operation_top + 2 * general_info.line_spacing, 'Duration (hh:mm)');
    doc.text(general_info.left_most, operation_top + 3 * general_info.line_spacing, 'End time');

    //Adding the information about the operation
    doc.setFont('helvetica', 'bold');
    doc.text(general_info.left_inside, operation_top + general_info.line_spacing, cfg['operation'].start_time);
    doc.text(general_info.left_inside, operation_top + 2 * general_info.line_spacing, cfg['operation'].duration);
    doc.text(general_info.left_inside, operation_top + 3 * general_info.line_spacing, cfg['operation'].end_time);

    //Observation section
    //var date_time = cfg['operation'].name.split("@");
    doc.text(general_info.left_most, observation_top, 'Observation:');
    doc.setFont('helvetica', 'normal');
    doc.text(general_info.left_most, observation_top + general_info.line_spacing, 'Bio Fauling');
    doc.text(general_info.left_most, observation_top + 2 * general_info.line_spacing, 'Pen Shape');
    doc.text(general_info.left_most, observation_top + 3 * general_info.line_spacing, 'Deadfish Liftup/Mort collector');

    //Adding the information about the operation
    doc.setFont('helvetica', 'bold');
    const bioFauling = cfg['bioFauling'] || ''; // Backward compatibility
    const penShape = cfg['penShape'] || '';
    const deadFish = cfg['deadFish'] || '';
    doc.text(general_info.left_inside, observation_top + general_info.line_spacing, bioFauling);
    doc.text(general_info.left_inside, observation_top + 2 * general_info.line_spacing, penShape);
    doc.text(
      general_info.left_inside + 2 * general_info.line_spacing,
      observation_top + 3 * general_info.line_spacing,
      deadFish.substring(0, 40)
    );
    doc.text(
      general_info.left_inside + 2 * general_info.line_spacing,
      observation_top + 3 * general_info.line_spacing + 5,
      deadFish.substring(40, 80)
    );
    doc.text(
      general_info.left_inside + 2 * general_info.line_spacing,
      observation_top + 3 * general_info.line_spacing + 10,
      deadFish.substring(80, 120)
    );

    await addPoiInfo({ poiStore, cfg, doc, general_info, screenShots });
    addscreenShots({ cfg, doc, general_info, screenShots });
  }
};

export default onGenerateReport;
