import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';

class ChangeViewButtons extends Component {
  render() {
    const { setView, view, operation } = this.props;
    return (
      <div data-html2canvas-ignore id="controls-area" style={{ position: 'absolute', top: '16px', right: '200px' }}>
        {operation?.id && (
          <>
            <Button
              inverted
              active={view === 'sideview' ? true : false}
              onClick={() => {
                setView('');
                setView('sideview');
                setTimeout(() => {
                  setView('');
                }, 200);
              }}
            >
              Side
            </Button>

            <Button
              inverted
              active={view === 'topview' ? true : false}
              onClick={() => {
                setView('topview');
                setTimeout(() => {
                  setView('');
                }, 200);
              }}
            >
              TOP
            </Button>
            {/* <Button
              inverted
              active={view === 'follow' ? true : false}
              // disabled
              onClick={() => {
                setView('follow');
                setTimeout(() => {
                  setView('');
                }, 200);
              }}
            >
              Follow
            </Button> */}
          </>
        )}
      </div>
    );
  }
}

export default ChangeViewButtons;
