import { Text } from '@react-three/drei';
import React, { useEffect, useState } from 'react';
import * as THREE from 'three';

const GuideLines = (props) => {

  const { cage, guidelines } = props;
  const [acrossArrow, setAcrossArrow] = useState();
  const [shortArrow, setShortArrow] = useState(null);
  const textColor = "#fff"

  useEffect(() => {
    if (cage) {
      const width = cage.r_top / 50;
      const length = cage.r_top * 2 - cage.r_top * 0.5;

      const acrossArrow = new THREE.Shape();
      acrossArrow.moveTo(width, 0);
      acrossArrow.lineTo(width, length - width * 2);
      acrossArrow.lineTo(width * 2, length - width * 2);
      acrossArrow.lineTo(0, length + width * 2);
      acrossArrow.lineTo(-width * 2, length - width * 2);
      acrossArrow.lineTo(-width, length - width * 2);
      acrossArrow.lineTo(-width, 0);
      setAcrossArrow(acrossArrow);

      const shortArrow = new THREE.Shape();
      shortArrow.moveTo(width, 0);
      shortArrow.lineTo(width, length / 2 - width * 2);
      shortArrow.lineTo(width * 2, length / 2 - width * 2);
      shortArrow.lineTo(0, length / 2 + width * 2);
      shortArrow.lineTo(-width * 2, length / 2 - width * 2);
      shortArrow.lineTo(-width, length / 2 - width * 2);
      shortArrow.lineTo(-width, 0);
      setShortArrow(shortArrow);
    }
  }, [cage]);

  return (
    <>
      {guidelines && shortArrow ? (
        <group name="guidelines" dispose={null}>
          <mesh name="accross" position={[0, cage.r_top * 0.2, 0]}>
            <shapeGeometry args={[acrossArrow]} />
            <meshLambertMaterial color={'#000000'} opacity={0.6} transparent wireframe={false} />
            <Text
              position={[0, cage.r_top *1.1, 0.1]}
              // rotation={[0, 0, -Math.PI / 2]}
              scale={[10, 10, 10]}
              color={textColor}
              text="Across"
            />
          </mesh>

          <mesh name="left" position={[-cage.r_top * 0.05, cage.r_top, 0]} rotation={[0, 0, Math.PI / 2]}>
            <shapeGeometry args={[shortArrow]} />
            <meshLambertMaterial color={'#000000'} opacity={0.6} transparent wireframe={false} />
            <Text
              position={[0, cage.r_top * 0.3, 0.1]}
              rotation={[0, 0, -Math.PI / 2]}
              scale={[10, 10, 10]}
              color={textColor}
              text="Left"
            />
          </mesh>

          <mesh name="right" position={[cage.r_top * 0.05, cage.r_top, 0]} rotation={[0, 0, -Math.PI / 2]}>
            <shapeGeometry args={[shortArrow]} />
            <meshLambertMaterial color={'#000000'} opacity={0.6} transparent wireframe={false} />
            <Text
              position={[0, cage.r_top * 0.3, 0.1]}
              rotation={[0, 0, Math.PI / 2]}
              scale={[10, 10, 10]}
              color={textColor}
              text="Right"
            />

          </mesh>
        </group>
      ) : null}
    </>
  );
};

export { GuideLines };

