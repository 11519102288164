import React from 'react';

const THeadSpan = ({ children }) => (
  <thead>
    <tr>
      <th colSpan="2">{children}</th>
    </tr>
  </thead>
);

const TRow = ({ title, value, onEnter, onLeave }) => (
  <tr className="poiTable" onMouseEnter={onEnter} onMouseLeave={onLeave}>
    <td>{title}</td>
    <td>{value}</td>
  </tr>
);

export { TRow, THeadSpan };
