import { extendObservable, makeAutoObservable, runInAction, action } from 'mobx';
import moment from 'moment';

import { superFetch, superFetchHeaders } from '../lib/fetch';

//import { Math } from 'three'

class Config {
  constructor() {
    makeAutoObservable(this);
    extendObservable(this, {
      mode: 'Idle',
      p_mode: 'First',
      loadingError: '',
      owner: undefined,
      site: undefined,
      cage: undefined,
      operation: undefined,
      type: undefined,
      bioFauling: '',
      penShape: '',
      deadFish: '',
      setBioFauling: action((bioFauling) => {
        this.bioFauling = bioFauling;
      }),
      setPenShape: action((penShape) => {
        this.penShape = penShape;
      }),
      setDeadFish: action((deadFish) => {
        this.deadFish = deadFish;
      }),
      getHref(type, id) {
        const base = '/api/v1/';
        switch (type) {
          case 'owner':
            return base + 'owner/' + id.toString();
          case 'site':
            return this.owner.id ? base + 'owner/' + this.owner.id.toString() + '/site/' + id.toString() : null;
          case 'cage':
            return this.owner.id && this.site.id
              ? base +
                  'owner/' +
                  this.owner.id?.toString() +
                  '/site/' +
                  this.site.id?.toString() +
                  '/cage/' +
                  id.toString()
              : null;
          case 'operation':
            return base + 'operation/' + id.toString();
          default:
            console.error('Uncaught href type');
        }
      },
    });
  }

  cage_angle = (depth) => {
    // depth is negative downwards
    if (!this.cage || depth > -this.cage.felling_height) {
      // if we have normal pen and not deep enough to be in con part
      return 0;
    } else {
      // Coned shape or in cone part of normal pen
      let hypotenus = Math.sqrt(this.cage.r_top ** 2 + this.cage.depth ** 2);

      // If normal pen, we find angle on coned part
      if (this.cage.felling_height > 0) {
        hypotenus = Math.sqrt(this.cage.r_top ** 2 + (this.cage.depth - this.cage.felling_height) ** 2);
      }
      let cage_angle = Math.asin(this.cage.r_top / hypotenus);
      return cage_angle;
    }
  };

  endOperation = () => {
    var currentdate = new moment();
    var end_time = currentdate.format('HH:mm');
    var bio_fauling = this.bioFauling;
    var pen_shape = this.penShape;
    var dead_fish = this.deadFish;
    // Calculating the duration in minutes
    // TODO: use datetime objects/unix timestamp because this will give negative values if we pass midnight :-(
    var start = new moment(this.operation.start_time, 'HH:mm');
    var duration = currentdate.diff(start); // Duration in milliseconds

    // Cheat to get moment to format the duration, works as long as duration is less than 1 day
    var duration_string = moment.utc(duration).format('HH:mm');

    // Preparing to update the database
    var data = this.operation;
    data.bio_fauling = bio_fauling;
    data.pen_shape = pen_shape;
    data.dead_fish = dead_fish;
    if (duration > 0) {
      data.duration = duration_string;
      data.end_time = end_time;
    }
    // Update the database
    superFetchHeaders(this.getHref('operation', this.operation.id), 'PATCH', data);
  };

  changeModeTo = (mode) => {
    switch (this.mode) {
      case 'Idle':
        if (mode === 'Idle') {
          return;
        } else if (this.operation === undefined) {
          alert('Please specify operation first!');
        } else {
          // fetch(this.getHref('operation', this.operation.id)).then((response) => response.json());
          this.p_mode = this.mode;
          this.mode = mode;
        }
        break;
      case 'Active':
      case 'View':
        if (mode !== 'Idle') {
          console.error('Forbidden stateflow. Ignoring.');
        } else {
          this.p_mode = this.mode;
          this.mode = mode;
          // this.cage = undefined;
          // this.operation = undefined;
          // this.type = undefined;
        }
        break;
      default:
        console.error('Uncaught mode');
    }
  };

  fillInEmptyFields(owner_id, site_id, cage_id) {
    superFetch('/api/v1/owner/' + owner_id.toString())
      .then((data) => {
        runInAction(() => {
          this.owner = data;
          // this.updateOptionsOfType('site');
        });
      })
      .then(() => {
        superFetch('/api/v1/owner/' + owner_id.toString() + '/site/' + site_id.toString()).then((data) => {
          runInAction(() => {
            this.site = data;
            // this.updateOptionsOfType('cage');
          });
        });
      })
      .then(() => {
        superFetch(
          '/api/v1/owner/' + owner_id.toString() + '/site/' + site_id.toString() + '/cage/' + cage_id.toString()
        ).then((data) => {
          runInAction(() => {
            this.cage = data;
          });
          // this.updateOperationOptions('cage_id', cage_id);
        });
      });
  }

  updateConfig(typeChosen, id) {
    try {
      switch (typeChosen) {
        case 'owner':
          superFetch(this.getHref(typeChosen, id))
            .then((data) => {
              runInAction(() => {
                this.owner = data;
              });
            })
            .then(() => {
              runInAction(() => {
                // this.updateOptionsOfType('site');
                this.site = undefined;
                this.cage = undefined;
                // this.operation = undefined;
                // this.updateOperationOptions('owner_id', id);
              });
            })
            .then(() => {
              runInAction(() => {
                // this.updateOptionsOfType('owner');
              });
            });
          break;
        case 'site':
          superFetch(this.getHref(typeChosen, id))
            .then((data) => {
              runInAction(() => {
                this.site = data;
              });
            })
            .then(() => {
              // this.updateOptionsOfType('cage');
              runInAction(() => {
                this.cage = undefined;
                // this.operation = undefined;
              });
              // this.updateOperationOptions('site_id', id);
            })
            .then(() => {
              // this.updateOptionsOfType('site');
            });
          break;
        case 'cage':
          superFetch(this.getHref(typeChosen, id))
            .then((data) => {
              runInAction(() => {
                this.cage = data;
              });
            })
            .then(() => {
              runInAction(() => {
                // this.operation = this.operation === 'new' ? 'new' : undefined;
              });
              // this.updateOperationOptions('cage_id', id);
            })
            .then(() => {
              // this.updateOptionsOfType('cage');
            });
          break;
        case 'operation':
          if (id === 'new') {
            this.operation = 'new';
            this.bioFauling = '';
            this.penShape = '';
            this.deadFish = '';
          } else if (!id) {
            this.operation = undefined;
          } else {
            superFetch(this.getHref(typeChosen, id))
              .then((data) => {
                runInAction(() => {
                  this.operation = data;
                  this.bioFauling = data.bio_fauling;
                  this.penShape = data.pen_shape;
                  this.deadFish = data.dead_fish;
                });
              })
              .then(() => {
                this.fillInEmptyFields(this.operation.owner_id, this.operation.site_id, this.operation.cage_id);
              });
          }
          break;
        case 'type':
          this.type = { id: id };
          break;
        default:
          console.log('Uncaught chosen type.');
      }
    } catch (error) {
      console.log(error);
    }
    // FILTER OPERATIONS ON THE CHOSEN IDS
  }
}

export default Config;
