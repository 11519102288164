import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import moment from 'moment';
import React from 'react';

const ROV = ({ x, y, z, cage_angle, cage, updateStatus, mode, rov_model }) => {
  const rz = Math.atan2(y - cage?.r_top, x) - Math.PI / 2;
  const rx = -Math.cos(rz) * cage_angle(z);
  const ry = -Math.sin(rz) * cage_angle(z);

  useFrame((state) => {
    if (mode === 'Active') {
      updateStatus([0, 0, moment.utc(state.clock.getElapsedTime() * 1000).format('HH:mm:ss'), 0, '']);
    }
  });

  return (
    <group name="rovObject" dispose={null} scale={0.3} position={[x, y, z]} rotation={[rx, ry, rz]}>
      {rov_model === 'other' ? <DefaultROV /> : <Fnc8 />}
    </group>
  );
};

const DefaultROV = () => {
  return (
    <>
      <mesh name="box" position={[0, -3.5, 0]}>
        <boxGeometry args={[5, 8, 5]} />
        <meshLambertMaterial color={'#1d3556'} />
      </mesh>
      <mesh name={'cylinder'}>
        <cylinderGeometry args={[2.5, 0, 5, 32, 1, false]} />
        <meshLambertMaterial color={'#1d3556'} />
      </mesh>
    </>
  );
};
const Fnc8 = () => {
  const { nodes } = useGLTF('models/rov.gltf');

  return (
    <>
      <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
      <mesh geometry={nodes.mesh_0_1.geometry} material={nodes.mesh_0_1.material} />
      <mesh geometry={nodes.mesh_0_2.geometry} material={nodes.mesh_0_2.material} />
      <mesh geometry={nodes.mesh_0_3.geometry} material={nodes.mesh_0_3.material} />
      <mesh geometry={nodes.mesh_0_5.geometry} material={nodes.mesh_0_5.material} />
    </>
  );
};

export { ROV };
