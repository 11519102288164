import { extend, useLoader, useFrame } from '@react-three/fiber';
import { useThree } from '@react-three/fiber';
import React, { useMemo, useRef } from 'react';
import * as THREE from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';

extend({ TextGeometry });

const POIs = (props) => {
  const { pois, cage, operation, poiHover } = props;
  const font = useLoader(FontLoader, 'helvetiker_regular.typeface.json');
  const config = useMemo(
    () => ({ font, size: 14, height: 3, bevelEnabled: true, bevelThickness: 0.3, bevelSize: 0.3, bevelOffset: 0 }),
    [font]
  );

  return (
    <>
      {pois?.allVisible
        .filter((poi) => cage && poi.cage_id === cage.id)
        .map((poi, index) => (
          <Poi key={index} poi={poi} index={index} config={config} operation={operation} poiHover={poiHover} />
        ))}
    </>
  );
};

const Poi = ({ poi, index, config, operation, poiHover }) => {
  const { x, y, z } = poi;
  const { camera } = useThree();
  const ref = useRef();

  useFrame(() => {
    if (ref.current) {
      const poi = ref.current;
      let angle = Math.atan2(camera.position.y - poi.position.y, camera.position.x - poi.position.x);
      let euler = new THREE.Euler(Math.PI / 2, -Math.PI / 2 + angle, 0, 'XYZ');
      poi.setRotationFromEuler(euler);

      const anglez = Math.atan2(
        camera.position.z - poi.position.z,
        Math.sqrt(
          (camera.position.x - poi.position.x) * (camera.position.x - poi.position.x) +
            (camera.position.y - poi.position.y) * (camera.position.y - poi.position.y)
        )
      );
      poi.rotateX(anglez);
    }
  });

  return (
    <group ref={ref} position={[x, y, z]} key={index}>
      <mesh>
        <sphereBufferGeometry
          args={[poiHover && poiHover === poi.id ? 2 : poi?.operation_id === operation?.id ? 1.4 : 1.2, 32, 32]}
          attach="geometry"
        />
        <meshStandardMaterial
          color={poi?.operation_id === operation?.id ? 0xaa0000 : 0x192e4b}
          metalness={poiHover && poiHover === poi.id ? 0.1 : 0.4}
        />
        {/* 0xffc83b */}
      </mesh>
      <mesh
        scale={poi?.operation_id === operation?.id ? 0.09 : 0.07}
        position={[0.8, -0.6, -2]}
        rotation={[-0.2, -Math.PI - 0.2, 0]}
      >
        <textGeometry args={[poi?.id.toString(), config]} />
        <meshPhongMaterial color={poi?.operation_id === operation?.id ? 0xffffff : 0xaaaaaa} />
      </mesh>
    </group>
  );
};

export { POIs };
