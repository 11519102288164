import React, { Component } from 'react';

import compass_needle from '../assets/images/compass_needle.png';

const color = 'rgb(29, 53, 86)';

const Boat = ({ w, h, tip, cx, cy }) => {
  /*

Draw boat and move it so the center is at (cx, cy)

(0,0)
      +       ^
     / \      |
    /   \     | tip
   /     \    |
  /       \   *
 +---------+  ^
 |         |  |
 |         |  | h
 |         |  |
 |         |  |
 |         |  |
 +---------+  * (w, h+tip)
 <--- w  -->
*/
  const moveX = cx - w / 2;
  const moveY = cy - (h + tip) / 2;
  return (
    <g transform={'translate(' + moveX + ',' + moveY + ')'}>
      <polygon
        points={`${w / 2},0 ${w},${tip} ${w},${h + tip} 0,${h + tip} 0,${tip}`}
        style={{ fill: color, stroke: 'none' }}
      />
    </g>
  );
};

// const Arrow = ({ cx, cy, radius, angle }) => {
//   const rad = (angle * Math.PI) / 180.0;
//   const r = radius - 40; // Adjust for marker size
//   const x2 = r * Math.cos(rad);
//   const y2 = r * Math.sin(rad);

//   return (
//     <line x1={cx - x2} y1={cy - y2} x2={cx + x2} y2={cy + y2} stroke={color} strokeWidth="5" markerEnd="url(#arrow)" />
//   );
// };

class ShowDockingIllustration extends Component {
  headingToAngle(heading) {
    return (360 + (-90 - heading)) % 360;
  }
  render() {
    const angle = this.headingToAngle(this.props.dock_angle);
    const isStarboard = this.props.is_starboard;

    // Size of image
    const width = 288;
    const height = 192;
    // Align in center of image
    const cx = width / 2;
    const cy = height / 2;
    // Boat size
    const boatWidth = 30;
    const boatHeight = height * 0.4;
    const boatTipH = boatHeight * 0.4;
    // Set size of circle to smallest of height/width and then some padding
    const radius = cy - 20;

    // Position boat next to circle on starboard/port side with a small margin
    const boatPos = isStarboard ? cx - radius - boatWidth / 2 - 5 : cx + radius + boatWidth / 2 + 5;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={'0 0 ' + width + ' ' + height}
        aria-labelledby="title"
      >
        <title id="title">Docking side and compass direction</title>
        <circle cx={cx} cy={cy} r={radius} strokeWidth="5" stroke={color} fill="none" strokeOpacity={0.7} />
        <Boat cx={boatPos} cy={cy} w={boatWidth} h={boatHeight} tip={boatTipH} />
        {/* <Arrow cx={cx} cy={cy} angle={angle} radius={radius - 10} /> */}
        <image
          transform={'rotate(' + (angle + 90) + ')'}
          transform-origin={'50% 50%'}
          href={compass_needle}
          height="200"
          width="200"
          x={45}
          y={-5}
        />
      </svg>
    );
  }
}

class ShowDocking extends Component {
  render() {
    return (
      <div>
        {this.props.is_starboard ? <div>Docking side: Starboard</div> : <div>Docking side: Port</div>}
        <span>
          {'Docking angle: '}
          <span>{this.props.dock_angle}</span>
        </span>
      </div>
    );
  }
}

export { ShowDocking, ShowDockingIllustration };
