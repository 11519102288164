import { OrbitControls } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

// const speed_vector_cpy = new THREE.Vector3();
// const rov_pos_cpy = new THREE.Vector3();

const CameraController = ({ cage, im_number, view }) => {
  const controlRef = useRef(null);
  const { camera } = useThree();
  // const { scene } = useThree();
  // const rov = scene.getObjectByName('rovObject');

  // useFrame(() => {
  //   if (rov && view === 'rovview') {
  //     rov_pos_cpy.copy(rov.position);
  //     camera.position.addVectors(rov.position, rov_pos_cpy.normalize().multiplyScalar(-20));
  //     camera.lookAt(rov.position);
  //   }

  //   if (rov && view === 'thirdview') {
  //     speed_vector_cpy.copy(new THREE.Vector3().subVectors(rov.position, new THREE.Vector3()).normalize());
  //     const cam_pos = new THREE.Vector3();
  //     if (speed_vector_cpy.lengthSq() > 0) {
  //       cam_pos.subVectors(rov_pos_cpy.multiplyScalar(0.7), speed_vector_cpy.multiplyScalar(6));
  //       camera.position.copy(cam_pos);
  //     }
  //     camera.lookAt(rov.position);
  //   }
  // });

  // useEffect(() => {
  //   controlRef.current &&
  //     controlRef.current.addEventListener('change', (e) => {
  //       console.log('change', e.target);
  //     });
  // }, [controlRef.current]);

  useEffect(() => {
    if (controlRef.current && camera && view) controlRef.current.target.set(-0.0001, cage.r_top, -cage.depth / 2);
    switch (view) {
      case 'sideview':
        // controlRef.current.target.set(0, cage.r_top, -cage.depth / 2);
        camera.position.set(0, -4 * cage.r_top, 2 * cage.r_top);
        camera.lookAt(new THREE.Vector3(0, cage.r_top, -cage.depth / 2));
        break;
      case 'topview':
        // controlRef.current.target.set(-0.0001, cage.r_top, -cage.depth / 2);
        camera.position.set(0, 0, 4.5 * cage.r_top);
        camera.lookAt(new THREE.Vector3(-0.0001, cage.r_top, 0));
        break;
      case 'screenShot':
        controlRef.current.target.set(0, cage.r_top, -cage.depth / 2);
        if (im_number === 4) {
          camera.position.set(0, 0, 4.5 * cage.r_top);
          camera.lookAt(new THREE.Vector3(-0.0001, cage.r_top, 0));
        } else {
          camera.position.set(
            4 * Math.cos(((im_number - 1) * 90 * Math.PI) / 180) * cage.r_top,
            4 * Math.sin(((im_number - 1) * 90 * Math.PI) / 180) * cage.r_top + cage.r_top, // Origo of cage is (0,cage.r_top)
            2 * cage.r_top
          );
          camera.lookAt(new THREE.Vector3(0, cage.r_top, -cage.depth / 2));
        }
        break;
      default:
        break;
    }
  }, [view, camera, controlRef.current, im_number]);

  return (
    <OrbitControls
      ref={controlRef}
      enabled={true}
      maxDistance={5 * cage.r_top}
      minDistance={25}
      enablePan={true}
      enableZoom={true}
      enableRotate={true}
      target={[0, cage.r_top, -cage.depth / 4]}
      position0={[0, -4 * cage.r_top, 2 * cage.r_top]}
    />
  );
};

export { CameraController };
