import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Icon, Checkbox } from 'semantic-ui-react';

import './ReadoutArea.css';
import { THeadSpan, TRow } from './Table.js';
import WarningMessages from './WarningMessages';

const Status = inject('warningsStore')(
  observer(
    class Status extends Component {
      render() {
        const { isWashing, cfg, recording, setRecording, operation_mode } = this.props;
        const waringMsg = this.props.warningsStore.getMasked().length > 0 ? <WarningMessages /> : '-';
        const washingIcon = isWashing ? <Icon name="checkmark" color="green" /> : <Icon name="x" color="red" />;

        const { operation } = cfg;

        return (
          <table className="readout-area left-side" style={{ bottom: '13em', left: '1em' }}>
            <THeadSpan>Status</THeadSpan>
            <tbody>
              {operation_mode === 'clean' && <TRow title="Washing on" value={washingIcon} />}
              {operation_mode === 'inspect' && cfg.mode === 'Active' ? (
                <tr className="poiTable">
                  <td>Recording on</td>
                  <td>
                    <Checkbox
                      style={{ marginTop: '10px' }}
                      checked={cfg.mode === 'Active' && recording}
                      onChange={() => setRecording(!recording)}
                      toggle
                      disabled={cfg.mode !== 'Active'}
                    />
                  </td>
                </tr>
              ) : cfg.mode === 'View' ? (
                <tr className="poiTable">
                  <td colSpan={2}>Viewing previous operation</td>
                </tr>
              ) : null}
              {cfg.mode !== 'Idle' && <TRow title="Duration" value={operation.duration} />}
              <TRow title="Warnings" value={waringMsg} />
            </tbody>
          </table>
        );
      }
    }
  )
);

export default Status;
