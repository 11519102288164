var Buffer = require('buffer/').Buffer;

async function getImageDimensions(file) {
  return new Promise(function (resolved, rejected) {
    var i = new Image();
    i.onload = function () {
      resolved({ w: i.width, h: i.height });
    };
    i.onerror = function (e) {
      console.log('Error', e);
      rejected(e);
    };
    i.src = file;
  });
}

async function getImage(file) {
  try {
    const dim1 = await getImageDimensions(file);
    const resp = await (await fetch(file)).arrayBuffer();
    const logo = Buffer.from(resp, 'binary');

    let data = {
      data: logo || null,
      width: dim1.w || 1,
      height: dim1.h || 1,
    };
    data['aspect'] = data['width'] / data['height'];

    return data;
  } catch (e) {
    console.log('No image loaded', e);
    return {
      data: null,
      width: 1,
      height: 1,
      aspect: 1,
    };
  }
}

export { getImage, getImageDimensions };
